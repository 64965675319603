import { Entity, StateMachine, World } from '@heliks/tiles-engine';
import { SpriteAnimation } from '@heliks/tiles-pixi';
import { Interaction, ScriptExecutable } from '../../modules/script';
import { VillageTransition } from '../../states';


export const enum PortalState {
  /** Portal is currently inactive and can not be interacted with. */
  Inactive,
  /** Portal is currently in the process of activating. */
  Activating,
  /** Portal is active and can be interacted with. */
  Active
}

export class PortalDoor implements ScriptExecutable {

  /** The portals current state. */
  public state = PortalState.Inactive;

  /** @internal */
  private _next?: PortalState;

  /** Contains `true` while the portal is {@link PortalState.Active active}. */
  public get isActive(): boolean {
    return this.state === PortalState.Active;
  }

  constructor() {
    console.log('PORTAL DOOR CONSTRUCTOR')
  }

  /** Activates the portal. */
  public activate(): this {
    if (this.state === PortalState.Inactive) {
      this._next = PortalState.Activating;
    }

    return this;
  }

  /** Deactivates the portal. */
  public deactivate(): this {
    if (this.state !== PortalState.Inactive) {
      this._next = PortalState.Inactive;
    }

    return this;
  }

  /** @internal */
  private setInactive(world: World, entity: Entity): void {
    world
      .storage(SpriteAnimation)
      .get(entity)
      .play('Off');

    this.state = PortalState.Inactive;
  }

  /**
   * Returns the {@link PortalState} that should be played next, if any. Calling this
   * function will consume this result.
   */
  private next(): PortalState | undefined {
    const state = this._next;

    this._next = undefined;

    return state;
  }

  /** Portal update tick while it's state is {@link PortalState.Inactive}. */
  private onInactive(world: World, entity: Entity): void {
    if (this.next() === PortalState.Activating) {
      const animation = new SpriteAnimation().play('Windup', false);

      this.state = PortalState.Activating;

      world.add(entity, animation);
    }
  }

  /** Portal update tick while it's state is {@link PortalState.Activating}. */
  private onActivating(world: World, entity: Entity): void {
    if (this.next() === PortalState.Inactive) {
      this.setInactive(world, entity);

      return;
    }

    const animation = world.storage(SpriteAnimation).get(entity);

    if (animation.isComplete()) {
      this.state = PortalState.Active;

      animation.play('On');
    }
  }

  /** Portal update tick while it's state is {@link PortalState.Active}. */
  private onActive(world: World, entity: Entity): void {
    if (this.next() === PortalState.Inactive) {
      this.setInactive(world, entity);

      return;
    }

    // Wait for user interaction.
    if (world.storage(Interaction).get(entity).isActiveNow()) {
      world.get(StateMachine).switch(new VillageTransition());
    }
  }

  /** @inheritDoc */
  public update(world: World, entity: Entity): boolean {
    switch (this.state) {
      case PortalState.Inactive:
        this.onInactive(world, entity);
        break;
      case PortalState.Activating:
        this.onActivating(world, entity);
        break;
      case PortalState.Active:
        this.onActive(world, entity);
        break;
    }

    return false;
  }

}