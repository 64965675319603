import { BehaviorNode, NodeState } from '@heliks/tiles-ai';
import { PetBehaviorData } from './pet-root';
import { Entity, Transform, World } from '@heliks/tiles-engine';
import { Movement, Seek } from '../../modules/movement';
import { RigidBody } from '@heliks/tiles-physics';
import { getClosestFood, playWalkAnimation } from './utils';
import { SpriteAnimation } from '@heliks/tiles-pixi';


export class PetFindFood implements BehaviorNode<PetBehaviorData> {

  /** @internal */
  private seek = new Seek(0, 0);

  /** @internal */
  private moveTo(world: World, movement: Movement, target: Entity): void {
    const position = world.storage(Transform).get(target).world;

    this.seek.x = position.x;
    this.seek.y = position.y;

    // Move towards food source.
    movement.behavior = this.seek;
  }

  /** @inheritDoc */
  public visit(data: PetBehaviorData): NodeState {
    const target = getClosestFood(data.world, data.entity.get(Transform));

    // Can no longer find any viable food.
    if (target === undefined) {
      return NodeState.Failure;
    }

    // If target is in range, we successfully found our food.
    if (data.entity.get(RigidBody).hasContactWith(target)) {
      return NodeState.Success;
    }

    const animation = data.entity.get(SpriteAnimation);
    const movement = data.entity.get(Movement);

    playWalkAnimation(animation, movement);

    this.moveTo(data.world, movement, target);

    return NodeState.Running;
  }

}
