/**
 * Component that identifies an object that was placed by the user as part of the
 * housing system.
 */
export class HousingObject {

  /**
   * @param objectId Id of the housing object.
   * @param cell Cell on the housing grid where this object is placed.
   */
  constructor(public readonly objectId: number, public readonly cell: number) {}

}
