import { Ticker } from '@heliks/tiles-engine';
import { SpriteAnimation } from '@heliks/tiles-pixi';
import { BehaviorNode, NodeState } from '@heliks/tiles-ai';
import { PetBehaviorData } from '../../pet/pet-root';


export class Wait implements BehaviorNode<PetBehaviorData> {

  private time = 0;

  constructor(public readonly ms: number) {}

  public visit(data: PetBehaviorData): NodeState {
    data.entity.get(SpriteAnimation).play('IdleSouth');

    this.time += data.world.get(Ticker).delta;

    return this.time >= this.ms
      ? NodeState.Success
      : NodeState.Running;
  }

  public reset(): void {
    this.time = 0;
  }

}
