import { EntityData, EntitySerializer, Struct, TypeRegistry, World } from '@heliks/tiles-engine';
import { Tilemap } from '@heliks/tiles-tilemap';
import { PlayerInventory } from './common';
import { Inventory } from './modules/item/inventory';
import { Housing } from './modules/map/housing/housing';
import { Egg, Pet } from './modules/pet';
import { ActiveMap } from './modules/map/active-map';


export const GAME_SAVE_MANAGER_STORAGE_KEY = 'game-save';



export interface GameSave {

  /**
   * Contains all *live* pets (i.W. no eggs, etc.). They will be restored and loaded
   * into the pet database at the start of the game.
   */
  pets: EntityData[];

  // Todo: data.
  inventory: any;


  entities: EntityData[];
  housing?: HousingData;
}

/**
 * Contains a serialized entity that was placed via the housing system and the cell
 * that it occupies on the housing grid.
 */
export interface HousingEntityData {
  cell: number;
  data: EntityData;
}

/** Contains serialized housing data. */
export interface HousingData {
  bg1: number[];
  bg2: number[];
  entities: HousingEntityData[];
}

/**
 * Serializes map {@link HousingHierarchy.entity entities} that were placed via the
 * housing system.
 */
function serializeMapHousingEntities(world: World, housing: Housing): HousingEntityData[] {
  const serializer = world.get(EntitySerializer);
  const entities = [];

  for (let i = 0, l = housing.hierarchy.entity.data.length; i < l; i++) {
    const entity = housing.hierarchy.entity.data[i];

    if (entity !== undefined) {
      entities.push({
        cell: i,
        data: serializer.serialize(world, entity)
      });
    }
  }

  return entities;
}

function serializeMapHousing(world: World, housing: Housing): HousingData {
  return {
    bg1: housing.hierarchy.ground1.get(Tilemap).data,
    bg2: housing.hierarchy.ground1.get(Tilemap).data,
    entities: serializeMapHousingEntities(world, housing)
  }
}

export function serializePlayerInventory(world: World): unknown {
  return world
    .get(TypeRegistry)
    .entry(Inventory)
    .strategy
    .serialize(world.get(PlayerInventory), world);
}

export function createGameSave(world: World): GameSave {
  const serializer = world.get(EntitySerializer);

  const data: GameSave = {
    inventory: serializePlayerInventory(world),
    pets: serializer.query(world).contains(Pet).serialize(),
    entities: [
      ...serializer.query(world).contains(Egg).serialize()
    ]
  };

  const map = world.get(ActiveMap).get();

  if (map.housing) {
    data.housing = serializeMapHousing(world, map.housing);
  }

  console.log(data);

  return data;
}

export function getGameSave(): GameSave | undefined {
  const save = localStorage.getItem(GAME_SAVE_MANAGER_STORAGE_KEY);

  if (save) {
    return JSON.parse(save);
  }
}

export function storeGameSave(save: GameSave): void {
  localStorage.setItem(GAME_SAVE_MANAGER_STORAGE_KEY, JSON.stringify(save));
}


