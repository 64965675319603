import { AssetCollection, AssetLoader, AssetStorage } from '@heliks/tiles-assets';
import {
  Entity,
  EntityRef,
  EntitySerializer,
  getRandomFloat,
  Grid,
  isDefined,
  Parent,
  shuffle,
  State,
  Transform,
  World
} from '@heliks/tiles-engine';
import { SpriteAnimation, SpriteRender, SpriteSheet } from '@heliks/tiles-pixi';
import { Tilemap } from '@heliks/tiles-tilemap';
import { TmxMapAsset, TmxSpawnMap, TmxTileLayer } from '@heliks/tiles-tmx';
import { getHousingTiles } from '../../housing';
import { getHousingLayer } from '../../maps';
import { ItemRegistry } from '../../modules/item';
import { Item } from '../../modules/item/item';
import { ActiveMap } from '../../modules/map/active-map';
import { GameMap } from '../../modules/map/game-map';
import { Housing } from '../../modules/map/housing/housing';
import { HousingAssets } from '../../modules/map/housing/housing-assets';
import { PetDB, PetFactory } from '../../modules/pet';
import { Interaction, Script } from '../../modules/script';
import { Toolbar } from '../../modules/tool';
import { DataGrid } from '../../modules/utils/data-grid';
import { RenderLayer } from '../../renderer';
import { GameSave, getGameSave, HousingData } from '../../save';
import { Josh } from '../../scripts/josh';
import { DragEntity } from '../../scripts/utils';
import { TransitionState } from '../common';
import { Garden } from './garden';
import { GardenAssets } from './garden-assets';


/** @internal */
function spawnNewGameEggs(world: World, map: GameMap, housing: Housing): void {
  const indexes = Array.from(housing.tiles);

  shuffle(indexes);

  for (let i = 0; i < 2; i++) {
    const index = indexes.pop();

    if (isDefined(index)) {
      const position = housing.grid.getPosition(index);

      position.x -= map.grid.cols / 2;
      position.y -= map.grid.rows / 2;

      // Position egg in the center of the tile. Also add a random offset to make
      // the placement look more natural.
      position.x += 0.5 + getRandomFloat(-0.25, 0.25);
      position.y += 0.5 + getRandomFloat(-0.25, 0.25);

      world.get(PetFactory).createEgg(world, position.x, position.y);
    }
  }
}

/** @internal */
function spawnPets(world: World, map: GameMap) {
  const db = world.get(PetDB);

  if (db.size > 0) {
    for (const record of db.records.values()) {
      record.entity(world);
    }
  }
  else {
    // We need the housing system because we spawn the eggs on empty housing tiles.
    if (! map.housing) {
      throw new Error('Map does not support housing.');
    }

    spawnNewGameEggs(world, map, map.housing);
  }
}

/** @internal */
function restoreHousingFromSave(world: World, housing: Housing, save: HousingData): void {
  const serializer = world.get(EntitySerializer);

  housing.hierarchy.ground1.get(Tilemap).setAll(save.bg1);
  housing.hierarchy.ground2.get(Tilemap).setAll(save.bg2);

  for (const item of save.entities) {
    housing.hierarchy.entity.set(item.cell, serializer.deserialize(world, item.data).build());
  }
}


export class GardenTransition extends TransitionState {

  /** Assets that are loaded during the transition. */
  private assets!: {
    /** Assets required to load the garden. */
    garden: AssetCollection<GardenAssets>;
    /** Assets required for the housing system. */
    housing: AssetCollection<HousingAssets>;
  }

  /** The toolbar will be spawned before the transition starts to fade out. */
  private toolbar?: Toolbar;

  /** @internal */
  private getMapAsset(world: World): TmxMapAsset {
    return world.get(AssetStorage).resolve(this.assets.garden.data.map);
  }

  /** @internal */
  private createHousingTilemap(world: World, parent: Entity, grid: Grid, layer = RenderLayer.Ground): EntityRef {
    const tilemap = this.assets.housing.data.tilemap(world, grid, layer);

    tilemap.view.zIndex = 1000;

    return world.reference(
      world.insert(
        new Parent(parent),
        new Transform(0, 0),
        tilemap,
      )
    );
  }

  /** Initializes map housing. */
  private setupHousing(world: World, parent: Entity, asset: TmxMapAsset, metaLayer: TmxTileLayer): Housing {
    // Todo: Infinite maps.
    const chunk = metaLayer.data[0];
    const grid = new Grid(chunk.grid.cols, chunk.grid.rows, 1, 1);
    const indexes = getHousingTiles(asset, metaLayer);

    const hierarchy = {
      ground1: this.createHousingTilemap(world, parent, chunk.grid, RenderLayer.Ground),
      ground2: this.createHousingTilemap(world, parent, chunk.grid, RenderLayer.Ground),
      entity: new DataGrid<Entity>()
    };

    return new Housing(grid, new Set(indexes), hierarchy);
  }

  /**
   * Initializes {@link Housing} for the given map `asset` and returns it if it supports
   * housing. If not, `undefined` is returned instead.
   */
  private initHousing(world: World, asset: TmxMapAsset, map: Entity, save?: GameSave): Housing | undefined {
    const layer = getHousingLayer(asset);

    // No housing layer. Further setup is not required.
    if (! layer) {
      return;
    }

    const housing = this.setupHousing(world, map, asset, layer);

    if (save?.housing) {
      restoreHousingFromSave(world, housing, save.housing);
    }

    return housing;
  }

  /** Spawns the gardens map as a {@link GameMap} into the world and returns it. */
  private spawn(world: World): GameMap {
    const save = getGameSave();

    // Spawn the map entity.
    const entity = world
      .create()
      .use(new TmxSpawnMap(this.assets.garden.data.map))
      .use(new Transform(0, 0))
      .build();

    const asset = this.getMapAsset(world);
    const gameMap = new GameMap(asset, asset.grid);

    // Attempt to initialize the housing system.
    gameMap.housing = this.initHousing(world, asset, entity, save);

    return gameMap;
  }

  /** @inheritDoc */
  public onTransitionStart(world: World) {
    const loader = world.get(AssetLoader);

    this.assets = {
      garden: loader.collection(GardenAssets),
      housing: loader.collection(HousingAssets)
    };
  }

  /** @inheritDoc */
  public onTransitionIn(world: World): State<World> {
    return new Garden();
  }

  /** @inheritDoc */
  public onTransitionOut(world: World): void {
    world.drop();

    const map = this.spawn(world);

    world.get(ActiveMap).set(map);

    spawnPets(world, map);

    // Todo: Fruit for testing.
    world
      .get(ItemRegistry)
      .build(
        world,
        2,
        RenderLayer.Player
      )
      .use(new Interaction(0.5, true))
      .use(new Item())
      .use(new Script(new DragEntity()).stop())
      .use(new Transform(0, 0))
      .build();
  }

  /** @inheritDoc */
  public onTransitionLoad(world: World): boolean {
    return this.assets.garden.isLoaded() && this.assets.housing.isLoaded();
  }

}
