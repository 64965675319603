import { Handle } from '@heliks/tiles-assets';
import { Entity, System, Transform, World } from '@heliks/tiles-engine';
import { SpriteAnimation, SpriteRender, SpriteSheet } from '@heliks/tiles-pixi';
import { RenderLayer } from '../renderer';


export class SfxManager implements System {

  private readonly entities: Entity[] = [];

  public spawn(world: World, spritesheet: Handle<SpriteSheet>, animation: string, x: number, y: number): Entity {
    const entity = world
      .create()
      .use(new Transform(x, y))
      .use(new SpriteRender(spritesheet, 0, RenderLayer.Player))
      .use(new SpriteAnimation().play(animation))
      .build();

    this.entities.push(entity);

    return entity;
  }

  public update(world: World): void {
    const animations = world.storage(SpriteAnimation);

    for (let i = this.entities.length - 1; i >= 0; i--) {
      const entity = this.entities[i];

      if (animations.get(entity).isComplete()) {
        this.entities.splice(i, 1);

        world.destroy(entity);
      }
    }
  }

}
