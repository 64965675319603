import { Script } from './script';
import { World, TypeSerializationStrategy } from '@heliks/tiles-engine';
import { ScriptRegistry } from './script-registry';


export interface ScriptData {
  isRunning: boolean;
  name: string;
  data?: unknown;
}


/** Serializes {@link Script} components. */
export class ScriptSerializer implements TypeSerializationStrategy<Script, ScriptData> {

  /** @inheritDoc */
  public deserialize(data: ScriptData, world: World): Script {
    const type = world.get(ScriptRegistry).get(data.name);

    const script = new Script(new type(data.data));

    script.isRunning = data.isRunning;

    return script;
  }

  /** @inheritDoc */
  public serialize(component: Script, world: World): ScriptData {
    const name = world.get(ScriptRegistry).name(component.script);

    if (! name) {
      throw new Error(`
        Can not serialize script. Executable ${component.script.constructor.name} is 
        not registered.
      `);
    }

    const result: ScriptData = {
      isRunning: component.isRunning,
      name
    };

    if (component.script.data) {
      result.data = component.script.data;
    }

    return result;
  }

}

