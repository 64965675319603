import { Bundle, AppBuilder } from '@heliks/tiles-engine';
import { ScriptRunner } from './script-runner';
import { ScriptRegistry, ScriptType } from './script-registry';
import { Script } from './script';
import { ScriptSerializer } from './script-serializer';
import { Interaction } from './interaction';


export class ScriptBundle implements Bundle {

  /** @internal*/
  private readonly registry = new ScriptRegistry();

  public script(name: string, type: ScriptType): this {
    this.registry.register(name, type);

    return this;
  }

  /** @inheritDoc */
  public build(builder: AppBuilder): void {
    builder
      .component(Script, new ScriptSerializer())
      .component(Interaction)
      .provide(ScriptRegistry, this.registry)
      .system(ScriptRunner);
  }

}
