import { EventQueue, Injectable, System } from '@heliks/tiles-engine';
import { default as Hammer } from 'hammerjs';


export enum GestureType {
  PinchStart  = 'pinchstart',
  PinchStop   = 'pinchstop',
  PinchMove   = 'pinchmove'
}

/** Emits touch gestures whenever they happen. */
@Injectable()
export class Gestures extends EventQueue<HammerInput> {

  /**
   * Hammer.js runtime used for recognizing touch gestures.
   *
   * @internal
   */
  private readonly hammer = new Hammer(document.body);

  /** @internal */
  private register(event: string): void {
    this.hammer.on(event, input => this.push(input));
  }

  /** Registers required event listeners. */
  public setup(): void {
    // Hammer disables pinch by default. Enable it.
    this.hammer.get('pinch').set({
      enable: true
    });

    this.register('pinchstart');
    this.register('pinchmove');
    this.register('pinchend');
  }


}

