import { Entity, EntityRef, Grid } from "@heliks/tiles-engine";
import { Tilemap } from "@heliks/tiles-tilemap";
import { DataGrid } from '../../utils/data-grid';


export interface HousingHierarchy {

  /** Flooring such as Soil, Carpets, etc. */
  ground1: EntityRef<Tilemap>;
  ground2: EntityRef<Tilemap>;

  /** Objects placed on entity layer. */
  entity: DataGrid<Entity>;

}

/** Contains the housing information of a map. */
export class Housing {

  /**
   * @param grid Boundaries of the housing grid.
   * @param tiles Indexes of all tiles that are eligible for housing.
   * @param hierarchy Entity hierarchy for "physical" housing layers.
   */
  constructor(
    public readonly grid: Grid,
    public readonly tiles: Set<number>,
    public readonly hierarchy: HousingHierarchy
  ) {}

}