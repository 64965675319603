import { World } from '@heliks/tiles-engine';
import ITEM_DEFS from '../data/items.json';
import { ItemRegistry } from '../modules/item';
import { ConsumableItem } from './consumable-item';
import { Seed } from './seed';
import { Shovel } from './shovel';
import { ItemTypeId } from './types';


/** Initializes all {@link ItemType item types}. */
export function setupItems(world: World): void {
  const registry = world.get(ItemRegistry);

  registry.setType(ItemTypeId.Consumable, new ConsumableItem());
  registry.setType(ItemTypeId.Seed, new Seed());
  registry.setType(ItemTypeId.Shovel, new Shovel());

  for (const def of ITEM_DEFS) {
    registry.setItem(def.id, def);
  }
}
