import { EntityBuilder } from '@heliks/tiles-engine';
import { ItemType } from 'modules/item/item-type';
import { ItemInfo } from '../modules/item';
import { ItemStack } from '../modules/item/inventory';
import { Tool } from '../modules/tool';
import { Dig } from '../tools';


/** Used to create fertile ground on housing tiles. */
export class Shovel implements ItemType {

  /** @inheritDoc */
  public compose(item: ItemInfo, builder: EntityBuilder): void {}

  /** @inheritDoc */
  public getAction(stack: ItemStack): Tool {
    return new Dig();
  }

}