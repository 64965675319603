import { AppBuilder, Bundle, OnInit, World } from '@heliks/tiles-engine';
import { ActiveTool } from './active-tool';
import { Tool } from './tool';
import { TOOLBAR_CONFIG_TOKEN, ToolbarConfig } from './toolbar';


export class ToolBundle implements Bundle, OnInit {

  /** @internal */
  private readonly buttons: ToolbarConfig[] = [];

  public tool(icon: number, tool: Tool): this {
    this.buttons.push({
      icon,
      tool
    });

    return this;
  }

  /** @inheritDoc */
  public build(builder: AppBuilder): void {
    builder
      .provide(TOOLBAR_CONFIG_TOKEN, this.buttons)
      .system(ActiveTool);
  }

  /** @inheritDoc */
  public onInit(world: World): void {
    for (const button of this.buttons) {
      button.tool.init?.(world);
    }
  }

}
