import { AssetLoader, Handle } from '@heliks/tiles-assets';
import { Vec2, World } from '@heliks/tiles-engine';
import { SpriteSheet } from '@heliks/tiles-pixi';
import { TerrainBrush, Tilemap } from '@heliks/tiles-tilemap';
import { warn } from '../log';
import { TerrainTileset } from '../maps';
import { SfxManager } from '../common/sfx-manager';
import { MapEditTool } from './map-edit-tool';


export class Dig extends MapEditTool {

  /**
   * Name of the terrain that we'll use to draw the effects of the shovel.
   *
   * @internal
   */
  private static readonly TERRAIN = 'Soil';

  /**
   * Contains the {@link TerrainBrush} that will be used to draw a soil terrain on the
   * current maps floor.
   *
   * @internal
   */
  private brush?: TerrainBrush;

  /**
   * Spritesheet that contains the SFX that is displayed when the user digs at a tile.
   *
   * @internal
   */
  private sfx!: Handle<SpriteSheet>;

  /** @internal */
  private readonly _scratch = new Vec2();

  /** @internal */
  private setupBrush(): boolean {
    const tilemap = this.housing.hierarchy.ground1.get(Tilemap);
    const tileset = tilemap.tilesets.getFromName(TerrainTileset.Ground);

    // If this happens, the housing system was probably initialized incorrectly.
    if (! tileset) {
      warn('Dig could not resolve tileset.');

      return false;
    }

    this.brush = new TerrainBrush(
      tilemap,
      tileset,
      tileset.tileset.getTerrain(Dig.TERRAIN)
    );

    return true;
  }

  /** @inheritDoc */
  public init(world: World) {
    this.sfx = world.get(AssetLoader).load('sfx/dig.aseprite.json');
  }

  /** @inheritDoc */
  public start(world: World, pos: Vec2): boolean {
    return super.start(world, pos) ? this.setupBrush() : false;
  }

  /** @internal */
  private playSFX(world: World, pos: Vec2): void {
    world.get(SfxManager).spawn(world, this.sfx, 'Dig', pos.x, pos.y);
  }

  /** @inheritDoc */
  public use(world: World, pos: Vec2): void {
    if (this.brush) {
      const cell = this.getHousingGridCellAt(pos);

      if (cell && this.housing.tiles.has(cell)) {
        const location = this.housing.grid.getLocation(cell, this._scratch);

        if (this.brush.draw(location.x, location.y)) {
          this.playSFX(world, pos);
        }
      }
    }
  }

}
