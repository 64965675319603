import { EntityBuilder } from '@heliks/tiles-engine';
import { ItemType } from 'modules/item/item-type';
import { ItemInfo } from '../modules/item';
import { ItemStack } from '../modules/item/inventory';
import { Tool } from '../modules/tool';
import { PlaceCrop } from '../tools';


/**
 * Implementation of seed items.
 *
 * Seeds can sow crops on fertile ground.
 */
export class Seed implements ItemType {

  /** @inheritDoc */
  public compose(item: ItemInfo, builder: EntityBuilder): void {}

  /** @inheritDoc */
  public getAction(stack: ItemStack): Tool {
    return new PlaceCrop(stack);
  }

}