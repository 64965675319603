export const enum EggAnimations {
  Idle = 'Idle',
  Hatch = 'Hatch',
  Jump = 'Jump',
  Wiggle = 'Wiggle'
}


export class Egg {

  /**
   * Hatching progress. When the progress reaches `1`, the egg will start to struggle,
   * indicating that it is ready to hatch. Upon interaction, the egg will then be hatched
   * and the egg will be destroyed.
   */
  public progress = 0;

  /**
   * When the egg is ready to hatch ({@link progress}, a struggle animation (jumping,
   * wiggling) will be played periodically. When this cooldown reaches `0`, the egg
   * is ready to play the next struggle animation.
   */
  public hatchStruggleCD = 0;

  /**
   * Indicates if the egg is cracked.
   *
   * An egg can be cracked once the hatch {@link progress} reaches `1`. When cracked,
   * the egg will play an animation, and spawn an appropriate pet afterwards.
   */
  public cracked = false;

  /**
   * Contains `true` if the egg can hatch.
   *
   * Eggs can hatch once their {@link progress} reaches `1`.
   */
  public get canHatch(): boolean {
    return this.progress >= 1;
  }

}

