import { Entity, Transform, Vec2, World } from '@heliks/tiles-engine';
import { CustomTile, Tilemap, Tileset } from '@heliks/tiles-tilemap';
import { PlayerInventory } from '../common';
import { checkGlobalTileIdxFlag, CustomTileProperties, TileFlag } from '../maps';
import { Inventory, ItemStack } from '../modules/item/inventory';
import { HousingEntity } from '../modules/map/housing/housing-entity';
import { Crop } from '../modules/needs/crop';
import { CropFactory } from '../modules/needs/crop-factory';
import { MapEditTool } from './map-edit-tool';


export class PlaceCrop extends MapEditTool {

  constructor(public readonly stack: ItemStack) {
    super();
  }

  /**
   * Contains the tilemap on which soil is placed.
   *
   * @internal
   */
  private get soil(): Tilemap<Tileset<CustomTile<CustomTileProperties>>> {
    return this.housing.hierarchy.ground1.get(Tilemap<Tileset<CustomTile<CustomTileProperties>>>);
  }

  /**
   * Returns the index of the housing tile at the world position `pos`. If the tile at
   * that position is not a valid target to place a crop on, or if the position is out
   * of bounds of the housing map, `undefined` is returned instead.
   *
   * @internal
   */
  private getTargetTileIdx(pos: Vec2): number | undefined {
    const tile = this.getHousingGridCellAt(pos);

    if (tile !== undefined) {
      const data = this.soil.get(tile);

      if (
        data !== 0
        && checkGlobalTileIdxFlag(this.soil.tilesets, data, TileFlag.IsSoil)
        && this.housing.hierarchy.entity.isFree(tile)
      ) {
        return tile;
      }
    }
  }

  /**
   * Calculates the exact world position where a crop entity should be placed if it
   * is located at the given `cell` index of the housing grid.
   *
   * @internal
   */
  private getCropPositionAt(cell: number): Vec2 {
    const pos = new Vec2();

    this.housing.grid.getPosition(cell, pos);

    // Position is the center of the housing grid cell.
    pos.x += (this.housing.grid.cellWidth / 2);
    pos.y += (this.housing.grid.cellHeight / 2);

    // Todo: Don't assume tilemap is placed at 0/0
    pos.x -= this.housing.grid.width / 2;
    pos.y -= this.housing.grid.height / 2;

    return pos;
  }

  /**
   * Spawns a crop entity at the given housing grid `cell`.
   *
   * @internal
   */
  private spawnCropEntity(world: World, cell: number): Entity {
    const pos = this.getCropPositionAt(cell);

    return world
      .get(CropFactory)
      .compose(world, 1)
      .use(new Crop(1))
      .use(new Transform(pos.x, pos.y))
      .use(new HousingEntity(cell))
      .build();
  }

  /** @inheritDoc */
  public use(world: World, pos: Vec2): void {
    // Our item stack of seeds is empty. Can not place any more crops.
    if (this.stack.empty()) {
      return;
    }

    const cell = this.getTargetTileIdx(pos);

    // Crops can not be placed on the targeted cell.
    if (cell === undefined) {
      return;
    }

    const entity = this.spawnCropEntity(world, cell);

    // Save entity to housing grid.
    this.housing.hierarchy.entity.set(cell, entity);

    world.get(PlayerInventory).consume(this.stack.slot);
  }

}
