import { Entity } from '@heliks/tiles-engine';
import { ScriptExecutable } from '../../modules/script';


export class TestScript implements ScriptExecutable {

  /** @internal */
  public update(_: unknown, entity: Entity): boolean {
    console.log(`Running test script for entity ${entity}`);

    return false;
  }

}