import { Handle, Load } from '@heliks/tiles-assets';
import { SpriteSheet } from '@heliks/tiles-pixi';
import { Texture } from 'pixi.js';


export class ShopAssets {

  @Load('ux/shop-bg.png')
  public bg!: Handle<Texture>;

  @Load('ux/shop-buttons.spritesheet')
  public buttons!: Handle<SpriteSheet>;

  @Load('ux/shop-item-bg.spritesheet')
  public itemBg!: Handle<SpriteSheet>;

}