import { EventQueue, System } from '@heliks/tiles-engine';


export enum KeyCode {
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'F4',
  F5 = 'F5',
  F6 = 'F6',
  F7 = 'F7',
  F8 = 'F8',
  F9 = 'F9',
  F10 = 'F10',
  F11 = 'F11',
  F12 = 'F12',

  A = 'KeyA',
  B = 'KeyB',
  C = 'KeyC',
  D = 'KeyD',
  E = 'KeyE',
  F = 'KeyF',
  G = 'KeyG',
  H = 'KeyH',
  I = 'KeyI',
  J = 'KeyJ',
  K = 'KeyK',
  L = 'KeyL',
  M = 'KeyM',
  N = 'KeyN',
  O = 'KeyO',
  P = 'KeyP',
  Q = 'KeyQ',
  R = 'KeyR',
  S = 'KeyS',
  T = 'KeyT',
  U = 'KeyU',
  V = 'KeyV',
  W = 'KeyW',
  X = 'KeyX',
  Y = 'KeyY',
  Z = 'KeyZ',
  Escape = 'Escape',
  Space = 'Space',
  MouseLeft = 'MouseLeft',
  MouseRight = 'MouseRight'
}

export interface InputHandler {
  /** Returns `true` if `key` is currently being pressed down. */
  isKeyDown(key: KeyCode): boolean;
}

/**
 * Calculates the value of an axis. Usually this is between `-1` and `1`.
 * @see Input.axis()
 */
export abstract class Axis {

  /** Calculates the axis value. */
  abstract value(input: InputHandler): number;

}

/**
 * Maps keys to an axis value, depending on which key is pressed.
 *
 * If the positive `pos` is pressed, the axis value will be `1`. For the negative `neg`
 * it is `-1`. If either none or both are pressed at the same time, the value is `0`.
 */
export class KeypressToAxis extends Axis {

  /**
   * @param pos Key that if pressed down, translates to a positive axis value.
   * @param neg Key that if pressed down, translates to a negative axis value.
   */
  constructor(public readonly pos: KeyCode, public readonly neg: KeyCode) {
    super();
  }

  /** @inheritDoc */
  public value(input: InputHandler): number {
    const pDown = input.isKeyDown(this.pos);
    const nDown = input.isKeyDown(this.neg);

    if (pDown && nDown) {
      return 0;
    }
    else if (pDown) {
      return 1;
    }
    else if (nDown) {
      return -1;
    }
    else {
      return 0;
    }
  }

}

export type AxisId = string | number | symbol;

export enum WheelDirection {
  Up,
  Down
}



/**
 * Handles input.
 *
 * Should run as early as possible in the system execution order so that all systems
 * are notified of input events on the same frame as they appear.
 */
export class Input implements InputHandler, System {

  private readonly axes = new Map<AxisId, Axis>();

  /** Keys that are currently being pressed down. */
  private readonly keysDown = new Set<KeyCode>();

  /** @internal */
  private readonly keysDownThisFrameQueue = new Set<KeyCode>();

  /** @internal */
  private readonly keysDownThisFrame = new Set<KeyCode>();

  /** Keys that were recently released. */
  private keysUp = new Set<KeyCode>();

  /**
   * Queue that contains an event every time the user tries to zoom the screen via
   * input. For example, on mobile devices this would happen via the "pinch" touch
   * gesture, while on desktop environments it would be the mouse wheel.
   */
  public readonly onZoom = new EventQueue<WheelDirection>();

  constructor() {
    // Key events.
    document.addEventListener('keydown', this.onKeyDown.bind(this));
    document.addEventListener('keyup', this.onKeyUp.bind(this));

    // Scroll events.
    document.addEventListener('wheel', this.onMouseWheel.bind(this))
  }

  /** Registers an `axis`. */
  public setAxis(id: AxisId, axis: Axis): this {
    this.axes.set(id, axis);

    return this;
  }

  /**
   * Returns the value (`-1` to `1`) of the axis matching `id`. Defaults to `0` if no
   * such axis exists.
   */
  public axis(id: AxisId): number {
    return this.axes.get(id)?.value(this) ?? 0;
  }

  public onMouseWheel(event: WheelEvent) {
    this.onZoom.push(event.deltaY > 0 ? WheelDirection.Up : WheelDirection.Down);
  }

  /** Virtually presses the given `key`. */
  public down(key: KeyCode): this {
    this.keysDown.add(key);
    this.keysDownThisFrameQueue.add(key);

    return this;
  }

  /** Virtually releases the given `key`. */
  public up(key: KeyCode): this {
    this.keysDown.delete(key);
    this.keysUp.add(key);

    return this;
  }

  /** @internal */
  private onKeyUp(event: KeyboardEvent): void {
    this.up(event.code as KeyCode);
  }

  /** @internal */
  private onKeyDown(event: KeyboardEvent): void {
    this.down(event.code as KeyCode);
  }

  /** Returns `true` if `key` is currently pressed. */
  public isKeyDown(key: KeyCode): boolean {
    return this.keysDown.has(key);
  }

  /**
   * Returns `true` if `key` was pressed down in the current frame.
   *
   * Note: Because of limitations some key-presses from the last frame are counted for
   *  this one as well.
   */
  public isKeyDownThisFrame(key: KeyCode): boolean {
    return this.keysDownThisFrame.has(key) || this.keysDownThisFrameQueue.has(key);
  }

  /** @inheritDoc */
  public update(): void {
    // Copy all keys that were pressed halfway through the last frame and copy them to
    // the current one. This is needed to circumvent the fact that the input handler
    // usually runs very early in the update loop and would therefore clear that info
    // before any other system had time to access it. This introduces a small side effect
    // were some key-presses from the last frame are counted for this frame as well.
    this.keysDownThisFrame.clear();

    for (const value of this.keysDownThisFrameQueue.values()) {
      this.keysDownThisFrame.add(value);
    }

    this.keysDownThisFrameQueue.clear();
  }

}
