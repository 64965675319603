import { BehaviorNode, NodeState } from '@heliks/tiles-ai';


export class ReturnState implements BehaviorNode<unknown> {

  constructor(public readonly state: NodeState) {}

  public static success(): ReturnState {
    return new ReturnState(NodeState.Success);
  }

  public static failure(): ReturnState {
    return new ReturnState(NodeState.Failure);
  }

  public static running(): ReturnState {
    return new ReturnState(NodeState.Running);
  }

  /** @inheritDoc */
  public visit(): NodeState {
    return this.state;
  }

}
