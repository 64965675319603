import { Vec2, XY } from '@heliks/tiles-engine';
import { Camera } from '@heliks/tiles-pixi';


export class Cursor {

  /** Flag that indicates that the cursor is currently being "pressed" down. */
  public isDown = false;

  /** Indicates if the cursor was pushed down on this frame. */
  public isDownNow = false;

  public dirty = true;

  /** Contains the cursors current screen position. */
  public readonly screen = new Vec2();

  /** Contains the cursors last calculated world position. */
  private readonly _world = new Vec2();

  public down(): this {
    this.isDown = true;
    this.isDownNow = true;
    this.dirty = true;

    return this;
  }

  public up(): this {
    this.isDown = false;
    this.isDownNow = false;
    this.dirty = true;

    return this;
  }

  /** Returns the cursors world position relative to the given `camera`. */
  public world(camera: Camera): Readonly<XY> {
    return camera.screenToWorld(this.screen.x, this.screen.y, this._world);
  }

}
