import { Parent, World } from '@heliks/tiles-engine';
import { PetAi } from './scripts/pet';
import { Script } from './modules/script';
import { Needs } from './modules/needs';
import { createGameSave, storeGameSave } from './save';
import { UiNode } from '@heliks/tiles-ui';


/**
 * Contains functions for debugging purposes. In development environments, an instance of
 * this will be assigned to `window.$debug` automatically.
 */
export class Debug {

  constructor(private readonly world: World) {}

  /** Initializes the debug functionality. */
  public static init(world: World): void {
    (window as any).$debug = new Debug(world);
  }

  public save(): void {
    storeGameSave(createGameSave(
      this.world
    ));
  }

  /** Prints information about all pets that are currently active. */
  public pets(): void {
    const query = this.world.query().contains(Needs).contains(Script).build();
    const table: {[key: number]: unknown} = {};

    for (const entity of query.entities) {
      const ref = this.world.reference(entity);

      const needs  = ref.get(Needs);
      const script = ref.get(Script<PetAi>);

      table[ entity ] = {
        // state: script.script.state,
        hunger: needs.hunger,
        sleepiness: needs.sleepiness,
        tiredness: needs.tiredness
      };
    }

    console.table(table);
  }

  public layout(): void {
    const query = this.world.query().contains(UiNode).excludes(Parent).build();

    console.group('Layout Roots');

    for (const entity of query.entities) {
      console.log(entity, this.world.storage(UiNode).get(entity).layout);
    }

    console.groupEnd();
  }

}

