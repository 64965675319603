import { AssetLoader } from '@heliks/tiles-assets';
import { Entity, Hierarchy, Storage, Subscriber, System, token, World } from '@heliks/tiles-engine';
import { AlignContent, composeUi, Rect, Size, UiEvent, UiNode, UiSprite } from '@heliks/tiles-ui';
import { Data } from '../../common/data';
import { RenderLayer } from '../../renderer';
import { ActiveTool } from './active-tool';
import { Tool } from './tool';


export interface ToolbarConfig {
  tool: Tool;
  icon: number;
}

export const TOOLBAR_CONFIG_TOKEN = token<ToolbarConfig[]>();

function updateButtonStates(world: World, buttons: Entity[]): void {
  const active = world.get(ActiveTool)

  const $data = world.storage(Data<Tool>);
  const $node = world.storage(UiNode<UiSprite>);

  for (const child of buttons) {
    const tool = $data.get(child).data;
    const widget = $node.get(child).getWidget();

    if (widget) {
      widget.spriteIndex = active.is(tool) ? 1 : 0;
    }
  }
}

export class Toolbar {

  /**
   * @param root Root entity of the toolbar.
   * @param component The {@link UiNode} component of the {@link root} entity.
   * @param subscription Interaction subscription.
   */
  constructor(
    public readonly root: Entity,
    public readonly component: UiNode,
    public readonly subscription: Subscriber<UiEvent>
  ) {}

  public static create(world: World): Toolbar {
    const config = world.get(TOOLBAR_CONFIG_TOKEN);

    const buttons = world.get(AssetLoader).load('ux/toolbar-button.spritesheet');
    const icons = world.get(AssetLoader).load('ux/icons-tools.spritesheet');

    const root = composeUi(world, {
      align: AlignContent.End,
      justify: AlignContent.End,
      layer: RenderLayer.UI,
      size: new Rect<Size>(
        Size.percent(1),
        Size.percent(1),
      )
    });

    for (let i = 0; i < config.length; i++) {
      const item = config[i];

      root
        .sprite(buttons, 0, {
          align: AlignContent.Center,
          justify: AlignContent.Center
        })
        .use(new Data(item.tool))
        .listen()
        .sprite(icons, item.icon);

      // If this is the first item, auto-select it.
      if (i === 0) {
        world.get(ActiveTool).set(item.tool);
      }
    }

    return new Toolbar(
      root.entity,
      root.component,
      root.component.onInteract.subscribe()
    );
  }

  /** Updates the toolbar. Should be called once per frame. */
  public update(world: World): void {
    const buttons = world.get(Hierarchy).children.get(this.root);

    if (buttons) {
      updateButtonStates(world, buttons);
    }

    for (const event of this.subscription.read()) {
      if (event.isUp()) {
        const tool = world.storage(Data<Tool>).get(event.target).data;

        world
          .get(ActiveTool)
          .set(tool);
      }
    }
  }

}