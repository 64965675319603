import { Injectable, ProcessingSystem, Query, QueryBuilder, Ticker, World } from '@heliks/tiles-engine';
import { Needs } from './modules/needs';
import { Movement } from './modules/movement';
import { awake, sleep } from './modules/needs/sleep';

export enum StatusState {
  /** No extra state is applied. */
  None,
  /** Sleeping entities can not move and over time reduces sleepiness. */
  Sleeping
}

export class Status {

  /** Current state of the character status. The exact effect depends on the state. */
  public state = StatusState.None;

}

@Injectable()
export class StatusSystem extends ProcessingSystem {

  constructor(private readonly ticker: Ticker) {
    super();
  }

  /** @inheritDoc */
  public build(builder: QueryBuilder): Query {
    return builder
      .contains(Needs)
      .contains(Status)
      .build();
  }

  /** @inheritDoc */
  public update(world: World): void {
    const _needs = world.storage(Needs);
    const _status = world.storage(Status);
    const _movement = world.storage(Movement);

    const delta = this.ticker.getDeltaSeconds();

    for (const entity of this.query.entities) {
      const needs = _needs.get(entity);
      const status = _status.get(entity);
      const movement = _movement.get(entity);

      switch (status.state) {
        case StatusState.None:
          awake(needs, delta);

          break;
        case StatusState.Sleeping:
          movement.stop();

          if(sleep(needs, delta)) {
            status.state = StatusState.None;
          }
        break;
      }
    }
  }

}
