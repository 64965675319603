import { BehaviorNode, NodeState } from '@heliks/tiles-ai';
import { getRandomInt } from '@heliks/tiles-engine';


export class RandomChance implements BehaviorNode<unknown> {

  /**
   * @param chance Chance percentage from `0` to `1`. E.g. `0.5` is equivalent to a
   *  chance of 50%.
   */
  constructor(public readonly chance: number) {}

  /** @inheritDoc */
  public visit(): NodeState {
    return (getRandomInt(100, 0) / 100) < this.chance ? NodeState.Success : NodeState.Failure;
  }

}
