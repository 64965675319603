import { Injectable, System } from '@heliks/tiles-engine';
import { Cursor } from './cursor';


/** Manages the software cursor. */
@Injectable()
export class CursorSystem implements System {

  constructor(private readonly cursor: Cursor) {}

  /** @internal */
  private setScreenPosition(x: number, y: number): void {
    this.cursor.screen.x = x;
    this.cursor.screen.y = y;
  }

  /** @internal */
  private onMouseMove(event: MouseEvent): void {
    this.setScreenPosition(event.clientX, event.clientY);
  }

  /** @internal */
  private onMouseDown(event: MouseEvent): void {
    this.setScreenPosition(event.clientX, event.clientY);
    this.cursor.down();
  }

  /** @internal */
  private onMouseUp(event: MouseEvent): void {
    this.setScreenPosition(event.clientX, event.clientY);
    this.cursor.up();
  }

  /** @internal */
  private onTouchEvent(event: TouchEvent): void {
    if (event.touches.length > 0) {
      // Todo: Multitask is not supported.
      this.setScreenPosition(event.touches[0].clientX, event.touches[0].clientY);
    }
  }

  /** @internal */
  private onTouchDown(event: TouchEvent): void {
    this.onTouchEvent(event);

    if (event.touches.length === 1) {
      this.cursor.down();
    }
    else {
      // If more than one touch press is detected we assume a touch gesture, hence why
      // the cursor is no longer being pressed down.
      this.cursor.up();
    }
  }

  /** @internal */
  private onTouchUp(event: TouchEvent): void {
    this.onTouchEvent(event);
    this.cursor.up();
  }

  /** @inheritDoc */
  public boot(): void {
    // Mouse events.
    document.body.addEventListener('mousemove', this.onMouseMove.bind(this));
    document.body.addEventListener('mousedown', this.onMouseDown.bind(this));
    document.body.addEventListener('mouseup', this.onMouseUp.bind(this));

    // Touch events.
    document.body.addEventListener('touchstart', this.onTouchDown.bind(this));
    document.body.addEventListener('touchend', this.onTouchUp.bind(this));
    document.body.addEventListener('touchcancel', this.onTouchUp.bind(this));
    document.body.addEventListener('touchmove', this.onTouchEvent.bind(this));
  }

  /** @inheritDoc */
  public update(): void {
    if (! this.cursor.dirty) {
      this.cursor.isDownNow = false;
    }

    this.cursor.dirty = false;
  }

}
