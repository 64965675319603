import { Handle, Load } from '@heliks/tiles-assets';
import { SpriteSheet } from '@heliks/tiles-pixi';
import { TmxMapAsset } from '@heliks/tiles-tmx';
import { Texture } from 'pixi.js';


export class VillageAssets {

  @Load('ux/shop-bg.png')
  public bg!: Handle<Texture>;

  @Load('maps/village.tmj')
  public map!: Handle<TmxMapAsset>;

  @Load('ux/shop-buttons.spritesheet')
  public buttons!: Handle<SpriteSheet>;

}