import { Decorator, NodeState, visit } from '@heliks/tiles-ai';
import { Status, StatusState } from '../../status';
import { EntityRef, World } from '@heliks/tiles-engine';
import { playSleepAnimation } from './utils';


export interface PetBehaviorData {
  world: World;
  entity: EntityRef;
}

export class PetRoot extends Decorator<PetBehaviorData> {

  /** @inheritDoc */
  public visit(data: PetBehaviorData): NodeState {
    switch (data.entity.get(Status).state) {
      case StatusState.None:
        return visit(this.child, data);
      case StatusState.Sleeping:
        playSleepAnimation(data.entity);

        return NodeState.Running;
    }

    return NodeState.Success;
  }

}
