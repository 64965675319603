import { Entity, StateMachine, World } from '@heliks/tiles-engine';
import { Interaction, ScriptExecutable } from '../../modules/script';
import { GardenTransition } from '../../states';


/** Takes the user back to the pet garden. */
export class GardenTeleport implements ScriptExecutable {

  /** @inheritDoc */
  public update(world: World, entity: Entity): boolean {
    if (world.storage(Interaction).get(entity).isActiveNow()) {
      world.get(StateMachine).switch(new GardenTransition());

      return true;
    }

    return false;
  }

}