import { Entity, EntitySerializer, Stack, State, World } from '@heliks/tiles-engine';
import { PlayerInventory } from '../common';
import { PetDB } from '../modules/pet';
import { GameSave, getGameSave } from '../save';
import { GardenTransition } from './garden';


/** @internal */
function restorePetDb(world: World, save: GameSave): void {
  const database = world.get(PetDB);
  const serializer = world.get(EntitySerializer);

  for (const data of save.pets) {
    database.add(serializer.extract(world, data));
  }
}

/** @internal */
function restoreInventory(world: World, save: GameSave): void {
  const inventory = world.get(PlayerInventory);

  inventory.balance = save.inventory.balance;

  for (const item of save.inventory.items) {
    inventory.add(item.itemId, item.amount);
  }
}

/** Initializes the game. This is also the root state of the game. */
export class Init implements State<World> {

  /** @inheritDoc */
  public onStart(world: World): void {
    const save = getGameSave();

    if (save) {
      restorePetDb(world, save);
      restoreInventory(world, save);
    }
  }

  /** @inheritDoc */
  public update(stack: Stack<State<World>>, world: World): void {
    const state = new GardenTransition();

    state.duration = 0;

    stack.push(state);
  }

}
