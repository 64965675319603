import { Entity, Ticker, World } from '@heliks/tiles-engine';
import { SpriteAnimation } from '@heliks/tiles-pixi';
import { Interaction, ScriptExecutable } from '../modules/script';


export enum JoshState {
  /** Do nothing. */
  Idle,
  /** In his shell. */
  Hiding,
  /** Retreating to shell. */
  Hide,
  /** Revealing from shell. */
  Show
}

export enum JoshAnimation {
  Idle = 'idle',
  Show = 'show',
  Hide = 'hide',
  Hiding = 'hiding',
}

/** Amount of time josh spends in {@link JoshState.Idle idle} state. */
export const JOSH_IDLE_TIME = 10000;

/** Josh AI implementation. */
export class Josh implements ScriptExecutable {

  /** Josh's current state. */
  private state = JoshState.Hiding;

  /** When josh is idle, time in ms until he starts hiding again. */
  private idleTimer = 0;

  /** Executed as long as josh is hiding. */
  private hiding(world: World, entity: Entity): void {
    world
      .storage(SpriteAnimation)
      .get(entity)
      .play(JoshAnimation.Hiding);

    // Wait for user interaction.
    if (world.storage(Interaction).get(entity).isActiveNow()) {
      this.state = JoshState.Show;
    }
  }

  /** Executed as long as josh is showing. */
  private show(world: World, entity: Entity): void {
    const animation = world.storage(SpriteAnimation).get(entity);

    animation.play(JoshAnimation.Show, false);

    if (animation.isComplete()) {
      this.state = JoshState.Idle;
      this.idleTimer = JOSH_IDLE_TIME;
    }
  }

  /** Executed as long as josh is showing. */
  private hide(world: World, entity: Entity): void {
    const animation = world.storage(SpriteAnimation).get(entity);

    animation.play(JoshAnimation.Hide, false);

    if (animation.isComplete()) {
      this.state = JoshState.Hiding;
    }
  }

  /** Executed as long as josh is idle. */
  private idle(world: World, entity: Entity): void {
    world
      .storage(SpriteAnimation)
      .get(entity)
      .play(JoshAnimation.Idle);

    this.idleTimer -= world.get(Ticker).delta;

    if (this.idleTimer <= 0) {
      this.state = JoshState.Hide;
    }
  }

  /** @inheritDoc */
  public update(world: World, entity: Entity): boolean {
    switch (this.state) {
      case JoshState.Idle:
        this.idle(world, entity);
        break;
      case JoshState.Show:
        this.show(world, entity);
        break;
      case JoshState.Hide:
        this.hide(world, entity);
        break;
      case JoshState.Hiding:
        this.hiding(world, entity);
        break;
    }

    return false;
  }

}