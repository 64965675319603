import { World, XY } from '@heliks/tiles-engine';
import { Drawable, Layers } from '@heliks/tiles-pixi';


/** Available renderer layers. */
export const enum RenderLayer {
  Ground = 'ground',
  Player = 'player',
  Above = 'above',
  UI = 'ui'
}

function sortZ(a: Drawable, b: Drawable): number {
  return a.zIndex - b.zIndex;
}

/** @internal */
function sortY(a: XY, b: XY): number {
  return a.y - b.y;
}

export function setupRendererLayers(world: World): void {
  const layers = world.get(Layers);

  layers.add(RenderLayer.Ground).sortBy(sortZ);
  layers.add(RenderLayer.Above);

  layers.add(RenderLayer.UI).zoom(false);

  // Player layer is sorted by depth.
  layers.after(RenderLayer.Player, RenderLayer.Ground).sortBy(sortY);
}
