import { TmxTileLayer, TmxMapAsset } from '@heliks/tiles-tmx';
import { MetaTile } from './maps';


/** Returns `true` if housing objects can be placed on the given `tileId`. */
export function isHousingTileId(tileId: number): boolean {
  return tileId === MetaTile.Housing;
}

/** Returns the indexes of all tiles on which housing objects can be placed. */
export function getHousingTiles(map: TmxMapAsset, layer: TmxTileLayer): number[] {
  const indexes = [];

  // Todo: Infinite layers.
  const chunk = layer.data[0];

  for (let i = 0, l = chunk.data.length; i < l; i++) {
    const gId = chunk.data[i];

    if (gId > 0 && isHousingTileId(map.tilesets.getFromGlobalId(gId).getLocalId(gId))) {
      indexes.push(i);
    }
  }

  return indexes;
}