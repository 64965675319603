import { BehaviorNode, NodeState } from '@heliks/tiles-ai';
import { PetBehaviorData } from './pet-root';
import { Movement } from '../../modules/movement';
import { RigidBody } from '@heliks/tiles-physics';
import { Food, Needs } from '../../modules/needs';
import { Entity, EntityRef, Parent, Transform, World } from '@heliks/tiles-engine';
import { SpriteAnimation } from '@heliks/tiles-pixi';
import { getClosestFood } from './utils';


/** @internal */
function eat(world: World, consumer: Entity, entity: Entity): void {
  const food = world.storage(Food).get(entity);

  food.consumer = consumer;

  // During the time that the food is being eaten, disable the rigid body so that the
  // entity can properly hold it.
  world.storage(RigidBody).get(entity).disabled = true;

  // Consumer holds the food while consuming.
  world.storage(Parent).set(entity, new Parent(consumer));
}

export class PetEat implements BehaviorNode<PetBehaviorData> {

  /** @internal */
  private food?: Entity;

  /** @internal */
  private getFood(world: World, consumer: EntityRef): Entity | undefined {
    if (this.food === undefined) {
      const food = getClosestFood(world, consumer.get(Transform));

      if (food !== undefined && consumer.get(RigidBody).hasContactWith(food)) {
        eat(world, consumer.entity, food)

        this.food = food;
      }
    }

    return this.food;
  }

  /** @inheritDoc */
  public visit(data: PetBehaviorData): NodeState {
    const target = this.getFood(data.world, data.entity);

    if (target === undefined) {
      return NodeState.Failure;
    }

    data.entity.get(Movement).stop();

    const animation = data.entity.get(SpriteAnimation);

    animation.play('Eat', true);
    animation.frameDuration = 150;

    if (animation.isComplete()) {
      const food = data.world.storage(Food).get(target);

      // Assign nutritional value to the entities needs.
      data.entity.get(Needs).eat(food);

      if (food.isConsumed) {
        return NodeState.Success;
      }
      else {
        animation.reset();
      }
    }

    return NodeState.Running;
  }

  /** @inheritDoc */
  public reset(): void {
    this.food = undefined;
  }

}
