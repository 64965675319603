import { Bundle, AppBuilder } from '@heliks/tiles-engine';
import { UpdateMovement } from './update-movement';
import { Movement } from './movement';


export class MovementBundle implements Bundle {

  /** @inheritDoc */
  public build(builder: AppBuilder): void {
    builder
      .component(Movement)
      .system(UpdateMovement);
  }

}
