import { Stack, State, World } from '@heliks/tiles-engine';
import { Camera, SpriteAnimation } from '@heliks/tiles-pixi';
import { Cursor } from '../../modules/input/cursor';
import { Input, KeyCode } from '../../modules/input/input';
import { PetAnimations, PetFactory } from '../../modules/pet';
import { Interaction, Script } from '../../modules/script';
import { ActiveTool } from '../../modules/tool';
import { OpenShop } from '../../scripts/open-shop';
import { Hand } from '../../tools';


export class Village implements State<World> {

  /** @internal */
  private cursor!: Cursor;

  /** @internal */
  private tool!: ActiveTool;

  /** @inheritDoc */
  public onStart(world: World): void {
    this.cursor = world.get(Cursor);

    this.tool = new ActiveTool(
      world.get(Camera),
      world.get(Cursor)
    );

    const hand = new Hand();

    hand.init(world);

    this.tool.set(hand);

    // Build the shopkeeper pet.
    world
      .get(PetFactory)
      .compose(world, -7, 4)
      .use(new Interaction(1, true))
      .use(new Script(new OpenShop()))
      .use(new SpriteAnimation().play(PetAnimations.IdleSouth))
      .build()
  }

  /** @inheritDoc */
  public update(stack: Stack<State<World>>, world: World): void {
    // Todo: Only for debugging purposes.
    if (world.get(Input).isKeyDownThisFrame(KeyCode.R)) {
      stack.pop();

      return;
    }

    this.tool.inUse = this.cursor.isDown;
    this.tool.update(world);
  }

}