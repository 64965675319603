import { BehaviorNode, NodeState } from '@heliks/tiles-ai';
import { Emote, Emotes } from '../../../common';
import { PetBehaviorData } from '../../pet/pet-root';


/**
 * Displays an {@link Emote} on an entity.
 */
export class ShowEmote implements BehaviorNode<PetBehaviorData> {

  /**
   * @param emote The {@link Emote} that should be displayed.
   * @param duration Duration for how long the emote should be displayed.
   */
  constructor(public readonly emote: Emote, public readonly duration = 1000) {}

  /** @inheritDoc */
  public visit(data: PetBehaviorData): NodeState {
    data.world.get(Emotes).show(
      data.world,
      data.entity.entity,
      this.emote,
      this.duration
    );

    return NodeState.Success;
  }

}
