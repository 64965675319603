import { AppBuilder, Bundle } from '@heliks/tiles-engine';
import { ActiveMap } from './active-map';
import { HousingAssets } from './housing/housing-assets';
import { HousingEntity } from './housing/housing-entity';
import { HousingObject } from './housing/housing-object';
import { HousingObjectParent } from './housing/housing-object-parent';
import { ScreenTransitionFactory } from './screen-transition';
import { SetCameraStartPosition } from './set-camera-start-position';


export class MapBundle implements Bundle {

  /** @inheritDoc */
  public build(builder: AppBuilder): void {
    builder
      .component(HousingEntity)
      .component(HousingObject)
      .component(HousingObjectParent)
      .provide(HousingAssets)
      .provide(ActiveMap)
      .provide(ScreenTransitionFactory)
      .system(SetCameraStartPosition);
  }

}
