import { Entity, World } from '@heliks/tiles-engine';
import { ScriptExecutable } from '../script';
import { Egg } from './egg';


export class CrackEgg implements ScriptExecutable {

  /** @inheritDoc */
  public start(world: World, entity: Entity): boolean {
    return world.storage(Egg).get(entity).canHatch;
  }

  /** @inheritDoc */
  public update(world: World, entity: Entity): boolean {
    world.storage(Egg).get(entity).cracked = true;

    return true;
  }

}
