import { AssetLoader } from '@heliks/tiles-assets';
import { Entity, Injectable, Parent, System, Ticker, Transform, Vec2, World } from '@heliks/tiles-engine';
import { SpriteAnimation, SpriteRender } from '@heliks/tiles-pixi';


export enum Emote {
  Frustrated = 'frustrated',
  Sleepy = 'sleepy'
}

export enum EmoteAnimation {
  Show = 'show',
  Hide = 'hide'
}

export interface ActiveEmote {
  alive: number;
  duration: number;
  entity: Entity;
  emote: Emote;
}


/**
 * Allows entities to play an emote.
 */
@Injectable()
export class Emotes implements System {

  private readonly emotes: ActiveEmote[] = [];

  constructor(
    private readonly loader: AssetLoader,
    private readonly ticker: Ticker
  ) {}

  public show(world: World, target: Entity, emote: Emote, duration = 2000): Entity {
    const handle = this.loader.load('ux/emotes.aseprite.json');
    const entity = world
      .create()
      .use(new Transform(0, 0, 0, 0, -2))
      .use(new Parent(target))
      .use(new SpriteRender(handle, 0))
      .use(new SpriteAnimation().play(EmoteAnimation.Show, false))
      .build();

    this.emotes.push({
      alive: 0,
      duration,
      entity,
      emote
    });

    return entity;
  }

  /** @inheritDoc */
  public update(world: World): void {
    const animations = world.storage(SpriteAnimation);

    for (const item of this.emotes) {
      const animation = animations.get(item.entity);

      if (animation.isComplete()) {
        switch (animation.playing) {
          case EmoteAnimation.Show:
            animation.play(item.emote);
            break;
          case EmoteAnimation.Hide:
            world.destroy(item.entity);
            break;
        }
      }
      else if (animation.playing === item.emote) {
        item.alive += this.ticker.delta;

        if (item.alive >= item.duration) {
          animation.play(EmoteAnimation.Hide);
        }
      }
    }
  }

}
