import { SpriteSheet } from '@heliks/tiles-pixi';
import { Handle } from '@heliks/tiles-assets';


export interface GrowStage {

  /**
   * Amount of time that a crop spends in that stage. After that duration has passed,
   * the crop will advance to the next stage.
   */
  duration: number;

  /**
   * Sprite index that is displayed for the crop entity while this is the active grow
   * stage that it is in.
   */
  sprite: number;

}

export class CropType {

  /**
   * @param id Unique identifier for this crop type.
   * @param spritesheet Spritesheet used for crop sprites.
   * @param stages The growth stages that it takes until crop is ready to harvest.
   * @param itemId Id of the item that is yielded when the crop is harvested.
   */
  constructor(
    public readonly id: number,
    public readonly spritesheet: Handle<SpriteSheet>,
    public readonly stages: GrowStage[],
    public readonly itemId: number
  ) {}

  public getGrowthStage(index: number): GrowStage {
    const stage = this.stages[ index ];

    if (! stage) {
      throw new Error(`Invalid growth stage ${index}.`);
    }

    return stage;
  }

  public hasNextGrowthStage(stage: number): boolean {
    return (this.stages.length - 1) > stage;
  }

}
