import {
  clamp,
  easeOutBounce,
  Entity,
  Injectable,
  InjectStorage,
  lerp,
  Query,
  QueryBuilder,
  ReactiveSystem,
  Storage,
  Ticker,
  Transform,
  Vec2,
  World
} from '@heliks/tiles-engine';
import { RigidBody } from '@heliks/tiles-physics';
import { Camera } from '@heliks/tiles-pixi';
import { Cursor } from '../input/cursor';


/**
 * Component that when attached to an entity, will drag the {@link Transform transform}
 * of that entity along the {@link Cursor cursor}.
 */
export class Drag {}

/**
 * Meta-data for the animation that is being played when an entity that was dragged
 * is being dropped back into the world.
 */
interface DropAnimation {
  entity: Entity;
  duration: number;
  height: number;
  start: Vec2;
  time: number;
}

@Injectable()
export class DragSystem extends ReactiveSystem {

  /** @internal */
  private readonly animations: DropAnimation[] = [];

  /** @internal */
  private readonly cursorPos = new Vec2();

  constructor(
    @InjectStorage(RigidBody) private readonly bodies: Storage<RigidBody>,
    @InjectStorage(Transform) private readonly transforms: Storage<Transform>,
    private readonly camera: Camera,
    private readonly cursor: Cursor,
    private readonly ticker: Ticker
  ) {
    super();
  }

  /** @inheritDoc */
  public build(builder: QueryBuilder): Query {
    return builder
      .contains(Drag)
      .contains(RigidBody)
      .contains(Transform)
      .build();
  }

  /** @inheritDoc */
  public onEntityAdded(world: World, entity: Entity): void {
    // As long as entity is being dragged, rigid body is disabled.
    this.bodies.get(entity).disabled = true;
  }

  /** @inheritDoc */
  public onEntityRemoved(world: World, entity: Entity): void {
    const start = new Vec2().copy(
      this.transforms.get(entity).world
    );

    const body = this.bodies.get(entity);

    // Re-enable the entities rigid body. We apply a small velocity to wake the
    // rigid body up so that it can collide with sensors when the item drops.
    body.disabled = false;
    body.setVelocity(0, 0.01);

    this.animations.push({
      duration: 500,
      entity,
      height: 0.5,
      start,
      time: 0
    });
  }

  /** @internal */
  private updateDropAnimations(): void {
    let i = this.animations.length;

    while (i--) {
      const animation = this.animations[i];

      animation.time += this.ticker.delta;

      const progress = clamp(animation.time / animation.duration, 0, 1);
      const transform = this.transforms.get(animation.entity);

      transform.world.y = lerp(animation.start.y, animation.start.y + animation.height, easeOutBounce(progress));

      // Remove animation from list if it has completed.
      if (progress >= 1) {
        const body = this.bodies.get(animation.entity);

        this.animations.splice(i, 1);


      }
    }
  }

  /** @inheritDoc */
  public update(world: World): void {
    super.update(world);

    /** Calculate cursor world position. */
    this.camera.screenToWorld(
      this.cursor.screen.x,
      this.cursor.screen.y,
      this.cursorPos
    );

    for (const entity of this.query.entities) {
      const transform = this.transforms.get(entity);

      transform.world.x = this.cursorPos.x;
      transform.world.y = this.cursorPos.y;
    }

    this.updateDropAnimations();
  }

}

