import { World } from '@heliks/tiles-engine';
import { Screenshot } from '@heliks/tiles-pixi';


/** Creates a screenshot of the game. */
export function createScreenshotDownload(world: World): void {
  world
    .get(Screenshot)
    .canvas()
    .toBlob(blob => {
      if (! blob) {
        return;
      }

      const anchor = document.createElement('a');

      document.body.append(anchor);

      anchor.download = 'Screenshot.png';
      anchor.href = URL.createObjectURL(blob);

      anchor.click();
      anchor.remove();
    }, 'image/png');
}
