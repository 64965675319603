import { AssetLoader, Handle } from '@heliks/tiles-assets';
import {
  Entity,
  EntityBuilder,
  EntityData,
  EntitySerializer,
  getRandomInt,
  Injectable,
  Transform,
  World
} from '@heliks/tiles-engine';
import { SpriteAnimation, SpriteRender, SpriteRenderer, SpriteSheet } from '@heliks/tiles-pixi';
import { Egg } from './egg';
import { Movement } from '../movement';
import { Needs } from '../needs';
import { Collider, RigidBody } from '@heliks/tiles-physics';
import { Interaction, Script } from '../script';
import { PetAi } from '../../scripts/pet';
import { Status } from '../../status';
import { CrackEgg } from './crack-egg';
import { Pet } from './pet';
import { Inventory } from '../item/inventory';
import { RenderLayer } from '../../renderer';
import { CustomName } from '../utils/custom-name';


@Injectable()
export class PetFactory {

  constructor(
    public readonly loader: AssetLoader,
    private readonly serializer: EntitySerializer
  ) {}

  public getEggSpritesheetHandle(): Handle<SpriteSheet> {
    return this.loader.load('spritesheets/egg.aseprite.json');
  }

  public getPetSpritesheetHandle(): Handle<SpriteSheet> {
    return this.loader.load('spritesheets/mong.aseprite.json');
  }

  private _egg(world: World, builder?: EntityBuilder) {
    const spritesheet = this.getEggSpritesheetHandle();

    builder = builder ?? world.create();

    return builder
      .use(
        RigidBody
          .dynamic()
          .dampen(1)
          .attach(Collider.circle(0.25))
      )
      .use(new SpriteRender(spritesheet, 0, RenderLayer.Player).setAnchor(0.5, 0.8))
      .use(new SpriteAnimation().play('Idle'))
      .use(new Script(new CrackEgg()))
      .use(new Interaction(0.4, true))
  }

  public createEgg(world: World, x: number, y: number): Entity {
    return this
      ._egg(world)
      .use(new Egg())
      .use(new Transform(x, y))
      .build()
  }

  public createPetSprite(): SpriteRender {
    return new SpriteRender(this.getPetSpritesheetHandle(), 1, RenderLayer.Player).setAnchor(0.5, 0.7);
  }

  public compose(world: World, x = 0, y = 0): EntityBuilder {
    return world
      .create()
      .use(new Transform(x, y))
      .use(this.createPetSprite())
      .use(
        RigidBody
          .dynamic()
          .dampen(10)
          .attach(Collider.circle(0.3))
      );
  }

  public createPet(world: World, x: number, y: number): Entity {
    const sprite = this.createPetSprite();

    /*
    sprite.material = new PetMaterial({
      color1: 16711935,
      color2: 16711935
    });
     */

    return world
      .create()
      .use(sprite)
      .use(
        RigidBody
          .dynamic()
          .dampen(10)
          .attach(Collider.circle(0.3))
      )
      .use(new CustomName('Pet-' + getRandomInt(1000, 1)))
      .use(new Pet())
      .use(new Inventory())
      .use(new Needs())
      .use(new Movement())
      .use(new Script(new PetAi()))
      .use(new SpriteAnimation())
      .use(new Status())
      .use(new Transform(x, y))
      .build();
  }


}
