import { CustomTile, LocalTilesetBag, Tileset as BaseTileset } from '@heliks/tiles-tilemap';
import { TmxTileLayer, TmxLayerType, TmxMapAsset } from '@heliks/tiles-tmx';

/** Meta layer names for loaded TMX maps. */
export const enum MapMetaLayer {

  /** Tile layer that specifies which tiles can be used for the housing system. */
  Housing = '#housing',

  /** Contains general meta tiles. */
  Meta = '#meta'

}

export const enum TileFlag {
  IsSoil = 1
}

export interface CustomTileProperties {
  flags?: number;
}

export type Tileset = BaseTileset<CustomTile<CustomTileProperties>>;

export function hasTileFlag(tile: CustomTile<CustomTileProperties>, flag: TileFlag): boolean {
  return tile.props.flags !== undefined ? Boolean(tile.props.flags | flag) : false;
}

export function checkGlobalTileIdxFlag(tilesets: LocalTilesetBag<Tileset>, gId: number, flag: TileFlag) {
  const local = tilesets.getFromGlobalId(gId);
  const tile = local.tileset.tile(local.getLocalIndex(gId));

  return tile && tile.props.flags !== undefined
    ? Boolean(tile.props.flags | flag)
    : false;
}



export const enum TerrainTileset {
  Ground = 'tileset:ground_terrain'
}

export const enum MetaTile {
  Housing = 6
}

export function getHousingLayer(map: TmxMapAsset): TmxTileLayer | undefined {
  return map.layers.find(item => item.name === MapMetaLayer.Housing && item.type === TmxLayerType.Tiles) as TmxTileLayer;
}
