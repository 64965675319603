import { BehaviorNode, NodeState } from '@heliks/tiles-ai';
import { Ticker, Vec2 } from '@heliks/tiles-engine';
import { SpriteAnimation } from '@heliks/tiles-pixi';
import { Movement, Wander } from '../../modules/movement';
import { PetBehaviorData } from './pet-root';
import { playWalkAnimation } from './utils';


export class PetWander implements BehaviorNode<PetBehaviorData> {

  /** @internal */
  private behavior = new Wander();

  /** MS that this state has been {@link NodeState.Running running}. */
  private runtime = 0;

  /**
   * @param duration Duration that the entity should wander until this node completes.
   */
  constructor(public readonly duration = 5000) {}

  /** @inheritDoc */
  public visit(data: PetBehaviorData): NodeState {
    const movement = data.entity.get(Movement);

    this.runtime += data.world.get(Ticker).delta;

    // If we wandered enough, state is done.
    if (this.runtime >= this.duration) {
      movement.behavior = undefined;

      return NodeState.Success;
    }

    movement.behavior = this.behavior;

    playWalkAnimation(data.entity.get(SpriteAnimation), movement);

    return NodeState.Running;
  }

  /** @inheritDoc */
  public reset(): void {
    this.runtime = 0;
  }

}

