import { Entity, EntityBuilder, Injectable, Transform, World } from '@heliks/tiles-engine';
import { Crop } from './crop';
import { SpriteRender } from '@heliks/tiles-pixi';
import { CropTypeManager } from './crop-type-manager';
import { Interaction, Script } from '../script';
import { Harvest } from './harvest';
import { RenderLayer } from '../../renderer';


@Injectable()
export class CropFactory {

  constructor(public readonly config: CropTypeManager) {}

  public  _compose(builder: EntityBuilder, cropId: number): EntityBuilder {
    const config = this.config.get(cropId);

    // Use sprite of first growth stage by default.
    const sprite = config.stages[0].sprite;

    return builder
      .use(new SpriteRender(config.spritesheet, sprite, RenderLayer.Player).setAnchor(0.5, 0.75))
      .use(new Interaction(0.5, true))
      .use(new Script(
        new Harvest({
          duration: 1000,
          height: 0.5
        })
      ));
  }

  public compose(world: World, cropId: number): EntityBuilder {
    return this._compose(world.create(), cropId)
  }

  public create(world: World, cropId: number, x: number, y: number): Entity {
    return this
      .compose(world, cropId)
      .use(new Crop(cropId))
      .use(new Transform(x, y))
      .build();
  }

}
