import { AppBuilder, Bundle } from '@heliks/tiles-engine';
import { Emotes } from './emotes';
import { PlayerInventory } from './player-inventory';
import { SfxManager } from './sfx-manager';


/** Provides the core services of the game. */
export class CommonBundle implements Bundle {

  /** @inheritDoc */
  public build(app: AppBuilder): void {
    app
      .provide(PlayerInventory)
      .system(Emotes)
      .system(SfxManager);
  }

}