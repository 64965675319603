import { Vec2, World } from '@heliks/tiles-engine';
import { Camera } from '@heliks/tiles-pixi';
import { UNIT_SIZE } from '../const';
import { Cursor } from '../modules/input/cursor';
import { Tool } from '../modules/tool';


export class DragCamera implements Tool {

  /** @internal */
  private readonly cameraPos = new Vec2();

  /** @internal */
  private readonly cursorPos = new Vec2();

  /** @internal */
  private readonly diff = new Vec2();

  /** @inheritDoc */
  public start(world: World) {
    this.cameraPos.copy(world.get(Camera).world);
    this.cursorPos.copy(world.get(Cursor).screen);

    return true;
  }

  /** @inheritDoc */
  public use(world: World, pos: Vec2): void {
    // Get distance between drag start position and current cursor position.
    this
      .diff
      .copy(world.get(Cursor).screen)
      .sub(this.cursorPos)
      .scale(1 / UNIT_SIZE);

    // Position of camera on drag start - difference is new camera position.
    world
      .get(Camera)
      .world
      .copy(this.cameraPos)
      .sub(this.diff);
  }

}
