import { Entity, World } from '@heliks/tiles-engine';
import { Interaction, InteractionState, ScriptExecutable } from '../../modules/script';
import { Drag } from '../../modules/utils/drag';


export class DragEntity implements ScriptExecutable {

  /** @inheritDoc */
  public start(world: World, entity: Entity): boolean {
    world.storage(Drag).set(entity, new Drag());

    return true;
  }

  /** @inheritDoc */
  public stop(world: World, entity: Entity): void {
    world.storage(Drag).remove(entity);
  }

  /** @inheritDoc */
  public update(world: World, entity: Entity): boolean {
    // Stop script when user stops interacting with the entity.
    return world.storage(Interaction).get(entity).state === InteractionState.None;
  }

}