import { BehaviorNode, NodeState } from '@heliks/tiles-ai';
import { PetBehaviorData } from '../../pet/pet-root';
import { Needs } from '../../../modules/needs';


export type Tester = (needs: Needs) => boolean;

export class CheckNeeds implements BehaviorNode<PetBehaviorData> {

  constructor(public readonly check: Tester) {}

  /** @inheritDoc */
  public visit(data: PetBehaviorData): NodeState {
    return this.check(data.entity.get(Needs)) ? NodeState.Success : NodeState.Failure;
  }

}
