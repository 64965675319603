import { ProcessingSystem, Query, QueryBuilder, Transform, Vec2, World } from '@heliks/tiles-engine';
import { Movement } from './movement';
import { RigidBody } from '@heliks/tiles-physics';


export class UpdateMovement extends ProcessingSystem {

  private velocity = new Vec2();

  /** @inheritDoc */
  public build(builder: QueryBuilder): Query {
    return builder
      .contains(Movement)
      .contains(RigidBody)
      .contains(Transform)
      .build();
  }

  /** @inheritDoc */
  public update(world: World): void {
    const bodies = world.storage(RigidBody);
    const movements = world.storage(Movement);
    const transforms = world.storage(Transform);

    for (const entity of this.query.entities) {
      const movement = movements.get(entity);

      if (movement.disabled) {
        continue;
      }

      const body = bodies.get(entity);
      const transform = transforms.get(entity);

      if (movement.behavior) {
        movement.direction.copy(body.getVelocity());

        const force = movement
          .behavior
          .update(movement, transform)
          .scale(movement.speed);

        // Apply movement speed factor, if any.
        if (movement.behavior.movementSpeedFactor !== undefined) {
          force.scale(movement.behavior.movementSpeedFactor);
        }

        // Apply force to body, limited by current movement speed.
        body.applyForce(
          force.x,
          force.y
        );


        // Add steering force to current movement direction.
        // movement.direction.add(force).normalize();

        // Scale direction by speed to receive the velocity that needs to be applied to
        // the entity to achieve the desired movement.
        // this.velocity.copy(movement.direction).scale(movement.speed);

        // body.setVelocity(this.velocity.x, this.velocity.y);
      }




      /*
      if (movement.isCanceled) {
        body.setVelocity(0, 0);

        // Remove target if movement is canceled.
        movement.isCanceled = false;

        continue;
      }

      if (movement.isMoving) {
        let vx = 0;
        let vy = 0;

        if (movement.isMovingToTarget) {
          if (movement.isAtTarget(transform)) {
            movement.isMoving = false;
          }
          else {
            // Move towards target.
            movement.direction.x = movement.target.x - transform.world.x;
            movement.direction.y = movement.target.y - transform.world.y;

            movement.direction.normalize();

            body.setVelocity(
              movement.direction.x * movement.speed,
              movement.direction.y * movement.speed
            );
          }
        }
        else {
          movement.direction.normalize();

          body.setVelocity(
            movement.direction.x * movement.speed,
            movement.direction.y * movement.speed
          );
        }

        /*
        if (movement.isAtTarget(transform)) {
          console.log('REACHED TARGET')
          body.setVelocity(0, 0);
          movement.isMoving = false;
        }
        else {
          // movement.direction.x = movement.target.x - transform.world.x;
          // movement.direction.y = movement.target.y - transform.world.y;
          // movement.direction.x += movement.avoidance.x;
          // movement.direction.y += movement.avoidance.y;
          // movement.direction.normalize();
          movement.velocity.x = movement.direction.x * movement.speed;
          movement.velocity.y = movement.direction.y * movement.speed;
          body.setVelocity(movement.direction.x, movement.direction.y);
        }

      }

       */
    }
  }

}
