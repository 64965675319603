import { Stack, State, Ticker, World } from '@heliks/tiles-engine';
import { Camera } from '@heliks/tiles-pixi';
import { ScreenTransition, ScreenTransitionFactory } from '../../modules/map/screen-transition';


/** Intermediate state that plays a fade out animation on the screen. */
export abstract class TransitionState implements State<World> {

  /** Time in ms that it takes for the transition to complete (per direction). */
  public duration = 250;

  /** @internal */
  private transition!: ScreenTransition;

  /** @internal */
  private ticker!: Ticker;

  /** Called at the beginning of the transition. */
  public abstract onTransitionStart?(world: World): void;

  /** Called once the screen transition is done fading the screen out. */
  public abstract onTransitionOut(world: World): void;

  /**
   * Called once the screen transition is done fading the screen back in. Returns the
   * state with which the transition state should be replaced with.
   */
  public abstract onTransitionIn(world: World): State<World>;

  /**
   * If defined, will be called once per frame after the screen transition is done fading
   * the screen. When it returns `true`, the transition will be reversed and the screen
   * will be faded back in again.
   *
   * Use this to manually delay the screen transition to wait for certain things to
   * complete (a.E. loading assets).
   */
  public abstract onTransitionLoad?(world: World): boolean;

  /** @inheritDoc */
  public onStart(world: World): void {
    this.transition = world.get(ScreenTransitionFactory).create(this.duration);
    this.ticker = world.get(Ticker);

    this.onTransitionStart?.(world);
  }

  /** @inheritDoc */
  public update(stack: Stack<State<World>>, world: World): void {
    this.transition.update(this.ticker.delta);

    if (this.transition.isComplete()) {
      if (this.transition.isOut) {
        // If we have to load hook, wait until it returns `true` to fade the screen
        // back in again. This should also delay the onTransitionOut hook.
        if (this.onTransitionLoad && ! this.onTransitionLoad(world)) {
          return;
        }

        this.transition.switch();

        // Reset camera zoom.
        world.get(Camera).zoom = 1;

        // Update transition once so that the transition sprite takes the zoom reset
        // into account without flickering for a frame.
        this.transition.update(Number.MIN_VALUE);

        this.onTransitionOut(world);
      }
      else {
        stack.switch(this.onTransitionIn(world));
      }
    }
  }

}
