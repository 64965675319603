import { Entity, Transform, Vec2, World } from '@heliks/tiles-engine';
import { RigidBody } from '@heliks/tiles-physics';
import { CameraEffects, MoveTo, ZoomTo } from '@heliks/tiles-pixi';
import { warn } from '../../log';
import { getObjectFromId } from '../../map/utils';
import { Script, ScriptExecutable } from '../../modules/script';
import { PortalDoor, PortalState } from './portal-door';


export interface AltarScriptParams {
  doorId: number;
}

export class PortalAltar implements ScriptExecutable<AltarScriptParams> {

  /** Contains the {@link RigidBody} component of the altar entity. */
  private body!: RigidBody;

  /** Contains the door entity to which the altar is connected to. */
  private door!: PortalDoor;

  constructor(public readonly data: AltarScriptParams) {}

  /** @internal */
  private get hasAltarOfferings(): boolean {
    return this.body.contacts.length > 0;
  }

  /** @inheritDoc */
  public start(world: World, entity: Entity): boolean {
    const door = getObjectFromId(world, this.data.doorId);

    if (door === undefined) {
      return warn('Altar can not find door entity.');
    }

    this.door = world.storage(Script<PortalDoor>).get(door).script;
    this.body = world.storage(RigidBody).get(entity);

    return true;
  }

  /** @inheritDoc */
  public update(world: World, entity: Entity): boolean {
    switch (this.door.state) {
      case PortalState.Active:
        if (! this.hasAltarOfferings) {
          this.door.deactivate();
        }

        break;
      case PortalState.Inactive:
        if (this.hasAltarOfferings) {
          this.door.activate();

          const transform = world.storage(Transform).get(entity);

          // Move camera to altar position.
          world.get(CameraEffects).add(new MoveTo(
            transform.world.x,
            transform.world.y,
            0.5
          )).add(new ZoomTo(1.5, 0.5));
        }

        break;
    }

    return false;
  }

}