import { ComponentList, Entity, Query, QueryBuilder, UUID } from '@heliks/tiles-engine';
import { Pet } from './pet';


/** Database that keeps track of pets. */
export class PetDB {

  /** Contains the component list of each pet, mapped to its {@link Pet.id Pet-ID}. */
  public readonly records = new Map<UUID, ComponentList>();

  /** Total amount of existing pets. */
  public get size(): number {
    return this.records.size;
  }

  /** Adds a new `record` to the database. */
  public add(record: ComponentList): this {
    const id = record.get(Pet).id;

    if (this.records.has(id)) {
      throw new Error(`Pet record ${id} already exists.`);
    }

    this.records.set(id, record);

    return this;
  }


}
