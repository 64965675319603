import { DEG90_RAD, getRandomFloat, Ignore, Transform, Vec2 } from '@heliks/tiles-engine';


/**
 * Radius of the circle from which a random angle will be selected to calculate a
 * displacement force. The force will be applied to the entity velocity to create
 * a more natural feel when wandering around. The greater the radius, the greater
 * the displacement.
 */
const DISPLACEMENT_RADIUS = 2;


export interface SteeringBehavior {

  /**
   * If defined, this factor is applied to the normal movement speed of the entity. This
   * is useful if a certain behavior wants to force the entity to perform that behavior
   * at a certain pace (a.E., half movement speed, etc.)
   */
  movementSpeedFactor?: number;

  update(movement: Movement, transform: Transform): Vec2;

}

export class Seek implements SteeringBehavior {

  private direction = new Vec2(0, 0);
  private steer = new Vec2(0, 0);

  public distance = Infinity;

  constructor(public x: number, public y: number) {}

  private readonly desired = new Vec2(0, 0);

  public get arrived() {
    return Math.floor(this.distance * 10) === 0;
  }

  public update(movement: Movement, transform: Transform) {
    this.desired.copy(this).sub(transform.world);

    this.distance = this.desired.magnitude();

    return this.desired.normalize();
  }

}

export class Arrive extends Seek {

  constructor(public x: number, y: number, public radius = 1) {
    super(x, y);
  }

  public update(movement: Movement, transform: Transform) {
    return super.update(movement, transform).scale(this.distance / this.radius);
  }

}


export class Wander implements SteeringBehavior {

  public readonly movementSpeedFactor = 0.5;

  private displacement = new Vec2(0, 0);
  private distance = 1.5;
  private circlePos = new Vec2(0, 0);
  private radius = 0.5;

  private angle = getRandomFloat(360);
  private maxAngleChange = +0.1;
  private minAngleChange = -0.1;

  public update(movement: Movement, transform: Transform) {
    // Calculate the "wander circles" center position, which should be in front of the
    // wandering entity.
    this.circlePos.copy(movement.direction).scale(this.distance);

    // Calculate displacement force.
    this.displacement.set(0, -1).scale(this.radius);

    const length = this.displacement.magnitude();

    this.displacement.x = Math.cos(this.angle) * length;
    this.displacement.y = Math.sin(this.angle) * length;

    // Slightly change wander angle by a random amount for the next frame.
    this.angle += getRandomFloat(this.maxAngleChange, this.minAngleChange);

    // Return final wander force.
    return this.circlePos.add(this.displacement);
  }


}

export class Movement {

  /**
   * Unit vector that points into the direction that the entity to which this component
   * is attached to, is currently facing.
   */
  public direction = new Vec2(0, 0);

  /**
   * If set to `true`, the movement controller will not move the entity. However, the
   * entity can still be moved from outside the movement system.
   */
  public disabled = false;

  @Ignore()
  public behavior?: SteeringBehavior;

  constructor(public speed = 5) {}

  public stop() {
    this.behavior = undefined;

    return this;
  }

  /** Returns `true` if a movement {@link behavior} is currently active. */
  public isMoving(): boolean {
    return Boolean(this.behavior);
  }

}

