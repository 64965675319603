import { Handle } from '@heliks/tiles-assets';
import { Entity, Subscriber, System, Vec2, World } from '@heliks/tiles-engine';
import { SpriteSheet } from '@heliks/tiles-pixi';
import { AlignContent, UiComposer, UiEvent } from '@heliks/tiles-ui';
import { PlayerInventory } from '../../common';
import { Data } from '../../common/data';
import { ItemRegistry } from '../../modules/item';


export class InventoryUi implements System {

  /** @internal */
  private readonly subscription: Subscriber<UiEvent>;

  constructor(public readonly container: UiComposer) {
    // Subscribe to all node interaction events.
    this.subscription = container.component.onInteract.subscribe();
  }

  public static create(world: World, parent: UiComposer, asset: Handle<SpriteSheet>): InventoryUi {
    const container = parent.row({
      margin: [0, 0, 0, -3]
    });

    const inventory = world.get(PlayerInventory);
    const items = world.get(ItemRegistry);

    for (const item of inventory.items) {
      const info = items.getItem(item.itemId);

      container
        .sprite(asset, 2, {
          align: AlignContent.Center,
          justify: AlignContent.Center,
          margin: [0, -11, 0, 0]
        })
        .sprite(info.sprite.spritesheet, info.sprite.spriteIndex)
        .use(new Data(item.slot))
        .listen();
    }

    return new InventoryUi(container);
  }

  /** @inheritDoc */
  public update(world: World): boolean {
    for (const event of this.subscription.read()) {
      if (event.isUp()) {
        world.get(PlayerInventory).select(
          world
            .storage(Data<number>)
            .get(event.target)
            .data
        );

        return true;
      }
    }

    return false;
  }

}
