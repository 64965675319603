import { Entity, StateMachine, Transform, World } from '@heliks/tiles-engine';
import { CameraEffects, MoveTo, ZoomTo } from '@heliks/tiles-pixi';
import { Interaction, ScriptExecutable } from '../modules/script';
import { Shop, ShopOffer } from '../states';


export class OpenShop implements ScriptExecutable {

  /** @inheritDoc */
  public update(world: World, entity: Entity): boolean {
    // Wait for user interaction.
    if (world.storage(Interaction).get(entity).isActiveNow()) {
      const transform = world.storage(Transform).get(entity);

      // Move camera to shopkeeper.
      // Todo: Move this to shop state.
      world.get(CameraEffects)
        .add(new MoveTo(transform.world.x, transform.world.y, 0.5))
        .add(new ZoomTo(1.5, 0.5));

      // Switch to shop state.
      world.get(StateMachine).push(
        new Shop([
          new ShopOffer(50, 10),
          new ShopOffer(100, 10),
          new ShopOffer(101, 15)
        ])
      );

      return true;
    }


    return false;
  }

}