import { Injectable, OnInit, Subscriber, System, World } from '@heliks/tiles-engine';
import { TmxMapAsset, TmxSpawner } from '@heliks/tiles-tmx';
import { MapProperties } from './map-properties';
import { Camera } from '@heliks/tiles-pixi';


@Injectable()
export class SetCameraStartPosition implements System, OnInit {

  /** @internal */
  private subscription!: Subscriber<TmxMapAsset<MapProperties>>;

  constructor(
    private readonly camera: Camera,
    private readonly spawner: TmxSpawner<TmxMapAsset<MapProperties>>
  ) {}

  /** @internal */
  public onInit(): void {
    this.subscription = this.spawner.onMapSpawned.subscribe();
  }

  /** @internal */
  public update(world: World): void {
    for (const tilemap of this.subscription.read()) {
      if (tilemap.properties.cameraStartPos) {
        const w = tilemap.grid.cols / 2;
        const h = tilemap.grid.rows / 2;

        this.camera.world.x = tilemap.properties.cameraStartPos.x - w;
        this.camera.world.y = tilemap.properties.cameraStartPos.y - h;
      }
    }
  }

}
