import { Injectable, ProcessingSystem, Query, QueryBuilder, Ticker, World } from '@heliks/tiles-engine';
import { Needs } from './needs';


/**
 * System that progresses `Needs`.
 *
 * @see Needs
 */
@Injectable()
export class NeedsSystem extends ProcessingSystem {

  constructor(private readonly ticker: Ticker) {
    super();
  }

  /** @inheritDoc */
  public build(builder: QueryBuilder): Query {
    return builder.contains(Needs).build();
  }

  /** @inheritDoc */
  public update(world: World): void {
    const store = world.storage(Needs);
    const delta = this.ticker.getDeltaSeconds();

    for (const entity of this.query.entities) {
      const needs = store.get(entity);

      // Get more hungry.
      needs.setHunger(needs.hunger + (needs.hungerPerSecond * delta));
    }
  }

}
