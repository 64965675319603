import { AssetStorage, Handle, Load } from '@heliks/tiles-assets';
import { Entity, Grid, World } from '@heliks/tiles-engine';
import { LocalTilesetBag, Tilemap, Tileset } from '@heliks/tiles-tilemap';
import { RenderLayer } from '../../../renderer';


export class HousingAssets {

  /** Contains ground textures. */
  @Load('tilesets/ground.tileset')
  public ground!: Handle<Tileset>;

  public tilesets(world: World, tilesets = new LocalTilesetBag()): LocalTilesetBag {
    const store = world.get(AssetStorage);

    tilesets.add(store.resolve(this.ground));

    return tilesets;
  }

  public tilemap(world: World, grid: Grid, layer?: RenderLayer): Tilemap {
    const tilemap = new Tilemap(grid, layer);

    // Todo
    tilemap.view.zIndex = 100;

    // Populate tilemap with tileset list.
    this.tilesets(world, tilemap.tilesets);

    return tilemap;
  }

}