import CONFIG from '../../data/crops.json';
import { Injectable } from '@heliks/tiles-engine';
import { AssetLoader } from '@heliks/tiles-assets';
import { CropType, GrowStage } from './crop-type';


interface CropTypeData {
  id: number;
  spritesheet: string;
  stages: GrowStage[];
  itemId: number;
}

/**
 * Manages available {@link CropType crop types}.
 */
@Injectable()
export class CropTypeManager {

  /**
   * Contains all available {@link CropType crop types}, mapped to their ID.
   *
   * @internal
   */
  private readonly types = new Map<number, CropType>();

  /**
   * @param loader {@see AssetLoader}
   */
  constructor(private readonly loader: AssetLoader) {}

  /** @internal */
  private createCropType(data: CropTypeData): CropType {
    const spritesheet = this.loader.load(data.spritesheet);

    return new CropType(
      data.id,
      spritesheet,
      data.stages,
      data.itemId
    );
  }

  /**
   * Initializes the crop type config. Should only be called once when the game engine
   * is first initialized.
   *
   * @internal
   */
  public init(): void {
    for (const item of CONFIG.crops) {
      this.types.set(item.id, this.createCropType(item));
    }
  }

  /** Returns the {@link CropType} with the given `id`.*/
  public get(id: number): CropType {
    const config = this.types.get(id);

    if (! config) {
      throw new Error(`Invalid crop type ID ${id}`);
    }

    return config;
  }

}
