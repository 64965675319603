import { AsepriteFormat } from '@heliks/tiles-aseprite';
import { AssetsBundle } from '@heliks/tiles-assets';
import { CoreBundle, runtime, Screen, Vec2 } from '@heliks/tiles-engine';
import { PhysicsBundle } from '@heliks/tiles-physics';
import { Box2dAdapter, Box2dDebugDraw } from '@heliks/tiles-physics-box2d';
import { LoadSpriteSheet, LoadTexture, PixiBundle, RendererSchedule } from '@heliks/tiles-pixi';
import { LoadTileset, TilemapBundle } from '@heliks/tiles-tilemap';
import { UiBundle, UiText } from '@heliks/tiles-ui';
import { CameraDrag } from './camera-drag';
import { CarouselBundle } from './carousel';
import { CommonBundle } from './common';
import { UNIT_SIZE } from './const';
import { Debug } from './debug';
import { setupItems } from './items';
import { GardenMapBundle } from './map/map-bundle';
import { InputBundle } from './modules/input/input-bundle';
import { ItemBundle } from './modules/item';
import { DrawMovementVectors, MovementBundle } from './modules/movement';
import { NeedsBundle } from './modules/needs';
import { PetBundle } from './modules/pet';
import { DrawInteractionRadius } from './modules/script';
import { ToolBundle } from './modules/tool';
import { CustomName } from './modules/utils/custom-name';
import { DragSystem } from './modules/utils/drag';
import { setupRendererLayers } from './renderer';
import { createGameSave, storeGameSave } from './save';
import { createScriptBundle } from './scripts';
import { Init } from './states';
import { Status, StatusSystem } from './status';
import { Dig, Hand } from './tools';


// Set default font for all UI texts.
UiText.defaultFont = 'VT323';

console.log('R1')


window.onload = function main() {
  const builder = runtime()
    .bundle(new CoreBundle())
    .component(Status)
    .type(CustomName)
    .provide(Screen, new Screen(new Vec2(180, 320)))
    .bundle(new InputBundle())
    .bundle(
      new AssetsBundle('assets')
        .use(new AsepriteFormat())
        .use(new LoadSpriteSheet())
        .use(new LoadTexture())
        .use(new LoadTileset())
    )
    .bundle(
      new PixiBundle({
        antiAlias: false,
        appendTo: '#stage',
        autoResize: true,
        background: 0x45283c,
        unitSize: UNIT_SIZE
      })
    )
    .bundle(new UiBundle())
    .bundle(new PhysicsBundle(new Box2dAdapter()))
    .bundle(new GardenMapBundle())
    .bundle(createScriptBundle())
    .bundle(new ItemBundle())
    .bundle(new MovementBundle())
    .provide(CameraDrag)
    .system(StatusSystem)
    .bundle(new NeedsBundle())
    .bundle(new PetBundle())
    .bundle(new CommonBundle())
    .bundle(
      new ToolBundle()
        .tool(0, new Hand())
        .tool(1, new Dig())
        .tool(2, new Dig())
    )
    .bundle(new CarouselBundle())
    .bundle(new TilemapBundle())
    .system(DragSystem)
    .run(setupRendererLayers)
    .run(setupItems)
    .run(Debug.init);

  if (!process.env.PRODUCTION) {
    // builder.system(DrawInteractionRadius);

    /*
    builder
      .system(Box2dDebugDraw, RendererSchedule.Update)
      .system(DrawInteractionRadius, RendererSchedule.Update)
      .system(DrawMovementVectors, RendererSchedule.Update);
     */
  }

  const app = builder.build();

  // In production mode, auto-save progress to account for app closing etc.
  if (process.env.PRODUCTION) {
    addEventListener('beforeunload', () => {
      storeGameSave(createGameSave(app.world));
    });
  }

  // Start the game loop.
  app.start(app.world.make(Init));
};

