import { clamp, Injectable, lerp, Screen, Uuid, Vec2 } from '@heliks/tiles-engine';
import { Camera, LayerId, Layers } from '@heliks/tiles-pixi';
import { Sprite, Texture } from 'pixi.js';
import { UNIT_SIZE } from '../../const';

export enum ScreenTransitionDirection {
  /** Screen is fading out -> end of current scene. */
  Out,
  /** Screen is fading in -> start of new scene. */
  In
}

export class ScreenTransition {

  /** Current transition direction. */
  public direction = ScreenTransitionDirection.Out;

  /** Current transition progress. */
  public progress = 0;

  private position = new Vec2();

  public get isOut(): boolean {
    return this.direction === ScreenTransitionDirection.Out;
  }

  /**
   * @param camera {@see Camera}
   * @param sprite Sprite used to create the transition effect.
   * @param layer Renderer layer on which the {@link sprite} is displayed.
   * @param duration Time in MS that it takes to complete the transition effect.
   */
  constructor(
    public readonly camera: Camera,
    public readonly sprite: Sprite,
    public readonly layer: LayerId,
    public duration = 10000
  ) {
  }

  /**
   * Updates the direction in which the transition effect is played. This resets all
   * current transition progress.
   */
  public setDirection(direction: ScreenTransitionDirection): this {
    this.direction = direction;
    this.progress = 0;

    return this;
  }

  /**
   * Switches the direction of the transition effect. This resets the current progress
   * of the transition.
   */
  public switch(): this {
    if (this.direction === ScreenTransitionDirection.In) {
      this.setDirection(ScreenTransitionDirection.Out);
    }
    else {
      this.setDirection(ScreenTransitionDirection.In);
    }

    return this;
  }

  /**
   * Returns the {@link ScreenTransitionDirection direction} in which the transition
   * effect is played.
   */
  public getDirection(): ScreenTransitionDirection {
    return this.direction;
  }

  public isComplete(): boolean {
    return this.progress >= 1;
  }

  /** @internal */
  private getSpriteOpacity(): number {
    return this.direction === ScreenTransitionDirection.Out
      ? lerp(0, 1, this.progress)
      : lerp(1, 0, this.progress);
  }

  /** @internal */
  private updateSpritePosition(): void {
    // Write 0/0 screen to world position and scale it to px position for sprite positioning.
    this.camera.screenToWorld(0, 0, this.sprite);

    this.sprite.x *= UNIT_SIZE;
    this.sprite.y *= UNIT_SIZE;
  }

  /** Updates the transition effect. */
  public update(delta: number): void {
    this.updateSpritePosition();

    this.progress = clamp(this.progress + (delta / this.duration), 0, 1);


    if (this.progress <= 1) {
      this.sprite.alpha = this.getSpriteOpacity();
    }
  }

}

@Injectable()
export class ScreenTransitionFactory {

  constructor(
    private readonly camera: Camera,
    private readonly layers: Layers,
    private readonly screen: Screen
  ) {}

  /**
   * @param duration Time in MS that it takes to complete the transition effect.
   */
  public create(duration = 1000) {
    // Create a layer on top of everything else.
    const layer = this.layers.add(Uuid.create());
    const sprite = new Sprite(Texture.WHITE);

    sprite.width = this.screen.size.x;
    sprite.height = this.screen.size.y;
    sprite.alpha = 0;
    sprite.tint = 0x0;

    layer.add(sprite);

    return new ScreenTransition(this.camera, sprite, layer.id, duration);
  }

}
