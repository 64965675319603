import { Entity } from "@heliks/tiles-engine";

/**
 * Component that marks an entity as a food source.
 *
 * Foods can be consumed by other entities to satisfy their hunger needs based on the
 * nutritional value of the eaten foods. If their consumption rate reaches a 100%, they
 * will be removed from the world.
 */
export class Food {

  /**
   * Value from `0` to `1` that indicates the progress of how much this food source has
   * been consumed. If this reaches `1` (100%), the entity to which this component is
   * attached to will be destroyed.
   */
  public consumption = 0;

  /**
   * If this food is currently being eaten, contains the entity that is eating it. As
   * long as this is not empty, no other entity can eat this food.
   */
  public consumer?: Entity;

  /**
   * Rate at which consumption progresses while eating this food on a scale from `0`
   * to `1`. More dense food takes longer to eat. Food with a density of `0` will be
   * eaten in single "bite", while food with a density of `1` can never be consumed.
   */
  public density = 0.9;

  public get isConsumed(): boolean {
    return this.consumption >= 1;
  }

  /**
   * @param nutrition Amount of hunger that is removed from entities when they eat this food.
   */
  constructor(public nutrition = 0.1) {}

  /**
   * Takes a "bite" out of this food source.
   *
   * In other words, the {@link consumption} rate of this food is progressed once based
   * on its own density.
   */
  public bite(): this {
    this.consumption += 1 - this.density;

    return this;
  }

}
