import { Handle } from '@heliks/tiles-assets';
import { SpriteSheet } from '@heliks/tiles-pixi';


/**
 *
 */
export interface ItemSpriteConfig {
  spritesheet: Handle<SpriteSheet>;
  spriteIndex: number;
}

/**
 * Describes the properties of an item. This is not an "occurrence" item, rather it is
 * the description of how it looks and behaves. If we have an axe it would say that it
 * is an item of type "weapon", its attack damage, etc.
 *
 * - `D`: Custom data attached to this item. This varies from item to item. If it were
 *  a consumable potion this could contain the HP that it recovers, etc.
 */
export class ItemInfo<D = unknown> {

  /**
   * @param id Unique ID of this item.
   * @param type Id of the items {@link ItemType item type}.
   * @param data Data attached to this item. What kind of data, depends on the item itself.
   * @param spritesheet Spritesheet that is used to display the item sprite.
   * @param spriteIndex Index of the sprite that is used to display the item sprite.
   */
  constructor(
    public readonly id: number,
    public readonly type: number,
    public readonly data: D,
    public readonly sprite: ItemSpriteConfig,
    public readonly icon: ItemSpriteConfig
  ) {}

}
