import { AssetLoader } from '@heliks/tiles-assets';
import {
  clamp,
  Entity,
  getRandomInt,
  Injectable,
  OnInit,
  ProcessingSystem,
  Query,
  QueryBuilder,
  Storage,
  Ticker,
  Transform,
  World
} from '@heliks/tiles-engine';
import { SpriteAnimation, SpriteRender } from '@heliks/tiles-pixi';
import { EGG_HATCH_DURATION } from '../../const';
import { Egg, EggAnimations } from './egg';
import { PetFactory } from './pet-factory';


/** Minimum cooldown in ms until an egg can struggle again. */
export const EGG_HATCH_STRUGGLE_CD_MIN = 3000;

/** Maximum cooldown in ms until an egg can struggle again. */
export const EGG_HATCH_STRUGGLE_CD_MAX = 8000;

/** Struggle animations that an {@link Egg} can play when it is ready to hatch. */
export const EGG_HATCH_STRUGGLE_ANIMATIONS: readonly EggAnimations[] = [
  EggAnimations.Jump,
  EggAnimations.Wiggle
];

/** @internal */
function getRandomStruggleAnimation(): EggAnimations {
  return EGG_HATCH_STRUGGLE_ANIMATIONS[ getRandomInt(EGG_HATCH_STRUGGLE_ANIMATIONS.length - 1) ];
}

/**
 * System that is responsible for hatching eggs.
 */
@Injectable()
export class EggHatcher extends ProcessingSystem implements OnInit {

  /** @internal */
  private animations!: Storage<SpriteAnimation>;

  /** @internal */
  private explosions: Entity[] = [];

  constructor(
    private readonly loader: AssetLoader,
    private readonly factory: PetFactory,
    private readonly ticker: Ticker
  ) {
    super();
  }

  /** @inheritDoc */
  public build(builder: QueryBuilder): Query {
    return builder
      .contains(Egg)
      .contains(Transform)
      .contains(SpriteRender)
      .contains(SpriteAnimation)
      .build();
  }

  /** @inheritDoc */
  public onInit(world: World): void {
    this.animations = world.storage(SpriteAnimation);
  }

  /** @internal */
  private showExplosionSFX(world: World, x: number, y: number): Entity {
    const handle = world.get(AssetLoader).load('sfx/egg-explosion.aseprite.json');

    const entity = world
      .create()
      .use(new Transform(x, y))
      .use(new SpriteRender(handle, 0))
      .use(new SpriteAnimation().play('Explode', false))
      .build();

    this.explosions.push(entity);

    return entity;
  }

  /** @internal */
  private cleanupFinishedExplosions(world: World): void {
    for (const entity of this.explosions) {
      if (this.animations.get(entity).isComplete()) {
        world.destroy(entity);
      }
    }
  }

  /**
   * Progresses the hatch of an egg entity. Should be called once per frame for each
   * egg that is cracked ({@link Egg.cracked}).
   */
  private hatch(world: World, entity: Entity): void {
    const animation = this.animations.get(entity);

    if (! animation.isPlaying(EggAnimations.Hatch)) {
      animation.play(EggAnimations.Hatch, false);
    }

    if (animation.isComplete()) {
      world.destroy(entity);

      const transform = world.storage(Transform).get(entity);

      this.showExplosionSFX(
        world,
        transform.world.x,
        transform.world.y - 1
      );

      this.factory.createPet(
        world,
        transform.world.x,
        transform.world.y
      );
    }
  }

  /**
   * Simulates "struggling" of an `egg` when it is ready to hatch.
   *
   * When the egg is playing its IDLE animation, the struggle cooldown is reduced until
   * a struggle animation can be played. When the struggle animation has completed, a
   * new struggle cooldown will be set until the next struggle will be played.
   *
   * Called once per frame for each egg that is ready to hatch.
   *
   * @see Egg
   */
  public struggle(owner: Entity, egg: Egg): void {
    const animation = this.animations.get(owner);

    if (animation.isPlaying(EggAnimations.Idle)) {
      if (egg.hatchStruggleCD <= 0) {
        animation.play(getRandomStruggleAnimation());
      }
      else {
        egg.hatchStruggleCD -= this.ticker.delta;
      }
    }
    else if (animation.isComplete()) {
      animation.play(EggAnimations.Idle);

      // Start cooldown until egg can struggle again.
      egg.hatchStruggleCD = getRandomInt(
        EGG_HATCH_STRUGGLE_CD_MAX,
        EGG_HATCH_STRUGGLE_CD_MIN
      );
    }
  }

  /** @inheritDoc */
  public update(world: World): void {
    const eggs = world.storage(Egg);

    this.cleanupFinishedExplosions(world);

    for (const entity of this.query.entities) {
      const egg = eggs.get(entity);

      if (egg.cracked) {
        this.hatch(world, entity);

        continue;
      }

      if (egg.progress >= 1) {
        this.struggle(entity, egg);
      }
      else {
        const progress = (this.ticker.delta / EGG_HATCH_DURATION) + egg.progress;

        egg.progress = clamp(progress, 0, 1);
      }
    }
  }

}
