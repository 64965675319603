/**
 * Component that allows an entity to grow a crop.
 *
 * A crop is an item that is produced by an entity (a.E.: a corn plant) over a certain
 * period of time. During growth, it will cycle through multiple growth stages until
 * the crop reaches maturity, at which point it can be harvested. Upon harvest, the
 * crop will yield the crop that was grown as an item.
 *
 * Entities with this component will have adjacent `SpriteRender` components updated
 * according the crops current growth stage.
 */
export class Crop {

  /**
   * Growth progress from `0` to `1` of the current growth {@link stage}. If the progress
   * reaches `1`, the progress will be reset and crop will move on to the next stage. If
   * the last growth stage is reached, the crop can be harvested and the progress will
   * remain at `0`.
   */
  public progress = 0;

  /**
   * Current growth stage. Will be increased when the growth {progress} reaches `1` until
   * the last stage of the crop is reached.
   */
  public stage = 0

  /**
   * Indicates if the current growth {@link stage} is complete.
   *
   * @see progress
   */
  public get complete(): boolean {
    return this.progress >= 1;
  }

  /**
   * @param cropId Id that references the crop type that is grown.
   */
  constructor(public cropId: number) {}

}


