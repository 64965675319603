import { Entity, World } from '@heliks/tiles-engine';
import { Pet } from './pet';
import { Egg } from './egg';


export function getPets(world: World): Entity[] {
  return Array.from(
    world.storage(Pet).entities()
  );
}

