import { AssetCollection, AssetLoader } from '@heliks/tiles-assets';
import { State, Transform, World } from '@heliks/tiles-engine';
import { SpriteAnimation, SpriteRender } from '@heliks/tiles-pixi';
import { TmxSpawnMap } from '@heliks/tiles-tmx';
import { Interaction, Script } from '../../modules/script';
import { RenderLayer } from '../../renderer';
import { Josh } from '../../scripts/josh';
import { TransitionState } from '../common';
import { Village } from './village';
import { VillageAssets } from './village-assets';


function spawnJosh(world: World, x = 0, y = 0): void {
  const handle = world.get(AssetLoader).load('spritesheets/animals/josh.aseprite.json');

  // Josh
  world
    .create()
    .use(new Transform(x, y))
    .use(new SpriteRender(handle, 0, RenderLayer.Player))
    .use(new SpriteAnimation())
    .use(new Script(new Josh()))
    .use(new Interaction(1.5))
    .build();
}

export class VillageTransition extends TransitionState {

  /** @internal */
  private assets!: AssetCollection<VillageAssets>;

  /** @inheritDoc */
  public onTransitionStart(world: World) {
    this.assets = world.get(AssetLoader).collection(VillageAssets);
  }

  /** @inheritDoc */
  public onTransitionOut(world: World): void {
    // Drop current world and insert village map.
    world
      .drop()
      .create()
      .use(new TmxSpawnMap(this.assets.data.map))
      .use(new Transform())
      .build();

    spawnJosh(world, -5.5, 15)
  }

  /** @inheritDoc */
  public onTransitionIn(): State<World> {
    return new Village();
  }

  /** @inheritDoc */
  public onTransitionLoad(): boolean {
    return true;
  }

}