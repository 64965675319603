import { Type } from '@heliks/tiles-engine';
import { ScriptExecutable } from './script';


export type ScriptType = Type<ScriptExecutable>;

export class ScriptRegistry {

  /** Maps script names to their respective {@link ScriptExecutable} types. */
  private readonly types = new Map<string, ScriptType>();

  public register(name: string, type: ScriptType): this {
    if (this.types.has(name)) {
      throw new Error(`Script name ${name} is already in use.`);
    }

    this.types.set(name, type);

    return this;
  }

  public get(name: string): ScriptType {
    const type = this.types.get(name);

    if (! type) {
      throw new Error(`Invalid script type ${name}`);
    }

    return type;
  }

  public create(name: string): ScriptExecutable {
    return new (this.get(name))();
  }

  public name(script: ScriptExecutable): string | undefined {
    for (const [name, type] of this.types.entries()) {
      if (script instanceof type) {
        return name;
      }
    }
  }

}
