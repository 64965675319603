import { EntityBuilder, Injectable, Struct, World } from '@heliks/tiles-engine';
import { DefaultObjectType as Base, TmxMapAsset, TmxObject, TmxObjectLayer } from '@heliks/tiles-tmx';
import { Interaction, Script } from '../modules/script';
import { ScriptRegistry } from '../modules/script/script-registry';
import { ObjectProps } from './objects';


/** @internal */
function gatherScriptParams(obj: TmxObject<ObjectProps>): Struct {
  const params: Struct = {};

  for (const name in obj.properties) {
    const segments = name.split('.');

    if (segments[0] === 'script' && segments.length === 2) {
      params[ segments[1] ] = obj.properties[ name as keyof ObjectProps ];
    }
  }

  return params;
}

/** Implementation for object type "Portal_Altar". */
@Injectable()
export class DefaultObjectType extends Base {

  /** @inheritDoc */
  public compose(world: World, entity: EntityBuilder, map: TmxMapAsset, layer: TmxObjectLayer, obj: TmxObject<ObjectProps>): void {
    super.compose(world, entity, map, layer, obj);

    // Attach script.
    if (obj.properties.script) {
      const script = world.get(ScriptRegistry).create(obj.properties.script);
      const component = new Script(script);

      script.data = gatherScriptParams(obj);

      if (obj.properties.scriptAutoStart) {
        component.start();
      }

      entity.use(component);
    }

    // Make entity interact-able.
    if (obj.properties.interaction) {
      console.log(obj.properties.interaction)

      const interaction = new Interaction(
        obj.properties.interaction.radius,
        obj.properties.interaction.trigger
      );

      interaction.offset.x = obj.properties.interaction.x ?? 0;
      interaction.offset.y = obj.properties.interaction.y ?? 0;

      entity.use(interaction);
    }
  }

}