import { SpriteAnimation } from '@heliks/tiles-pixi';
import { BehaviorNode, NodeState } from '@heliks/tiles-ai';
import { PetBehaviorData } from '../../pet/pet-root';



export class PlayAnimation implements BehaviorNode<PetBehaviorData> {

  constructor(public readonly name: string, public frameDuration = 100) {}

  public visit(data: PetBehaviorData): NodeState {
    const animation = data.entity.get(SpriteAnimation);

    animation.play(this.name);
    animation.frameDuration = this.frameDuration;

    return animation.isComplete() ? NodeState.Success : NodeState.Running;
  }

}
