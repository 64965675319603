import { Entity, Query, Storage, Vec2, World } from '@heliks/tiles-engine';
import { Interaction, InteractionState, Script } from '../modules/script';
import { Tool } from '../modules/tool';


export class Interact implements Tool {

  /** @internal */
  private isFirstFrame = false;

  /**
   * @param entity The entity that we are interacting with.
   */
  constructor(private readonly entity: Entity) {}

  /** @inheritDoc */
  public start(world: World, pos: Vec2): boolean {
    this.isFirstFrame = true;

    return true;
  }

  /** @inheritDoc */
  public stop(world: World): void {
    world.storage(Interaction).get(this.entity).state = InteractionState.None;
  }

  /** @inheritDoc */
  public use(world: World, pos: Vec2): void {
    const component = world.storage(Interaction).get(this.entity);

    if (this.isFirstFrame) {
      component.state = InteractionState.Start;

      // If the interaction acts as a script trigger, run the adjacent script component.
      if (component.trigger) {
        world.storage(Script).get(this.entity).start();
      }

      this.isFirstFrame = false;
    }
    else {
      component.state = InteractionState.Active;
    }
  }

}