import {
  Entity,
  Injectable,
  OnInit,
  Query,
  QueryBuilder,
  ReactiveSystem,
  Transform,
  World
} from '@heliks/tiles-engine';
import { Camera, Stage } from '@heliks/tiles-pixi';
import { Graphics } from 'pixi.js';
import { RenderLayer } from '../../renderer';
import { Movement } from './movement';


/**
 * Renderer plugin that draws debug vectors for entity movement.
 *
 * @see RendererPlugin
 */
@Injectable()
export class DrawMovementVectors extends ReactiveSystem implements OnInit {

  /** @internal */
  private readonly graphics = new Map<Entity, Graphics>();

  constructor(private readonly stage: Stage, private readonly camera: Camera) {
    super();
  }

  /** @inheritDoc */
  public build(builder: QueryBuilder): Query {
    return builder.contains(Movement).contains(Transform).build();
  }

  /** @inheritDoc */
  public onInit(world: World): void {
    this.boot(world);
  }

  /** @inheritDoc */
  public onEntityAdded(world: World, entity: Entity): void {
    const graphics = new Graphics();

    graphics.zIndex = 9999;

    this.stage.add(graphics, RenderLayer.UI);
    this.graphics.set(entity, graphics);
  }

  /** @inheritDoc */
  public onEntityRemoved(world: World, entity: Entity): void {
    const graphics = this.graphics.get(entity);

    if (graphics) {
      this.graphics.delete(entity);
      this.stage.remove(graphics);

      graphics.destroy();
    }
  }

  /** @inheritDoc */
  public update(world: World): void {
    super.update(world);

    const movements = world.storage(Movement);
    const transforms = world.storage(Transform);

    for (const entity of this.query.entities) {
      const graphics = this.graphics.get(entity);

      if (graphics) {
        const movement = movements.get(entity);
        const transform = transforms.get(entity);

        let color = 0xFFFF00;

        // Draw vector as red if movement is disabled for this entity.
        if (movement.disabled) {
          color = 0xFF0000;
        }


        graphics.clear();

        // Direction vector.
        graphics
          .moveTo(0, 0)
          .lineStyle(1, color)
          .lineTo(
            movement.direction.x * this.camera.unitSize,
            movement.direction.y * this.camera.unitSize
          );

        // Avoidance vector.
        /*
        graphics
          .moveTo(0, 0)
          .lineStyle(1, 0xFFAF5E)
          .lineTo(
            movement.avoidance.x * this.screen.unitSize,
            movement.avoidance.y * this.screen.unitSize
          );

         */


        graphics.x = transform.world.x * this.camera.unitSize;
        graphics.y = transform.world.y * this.camera.unitSize;
      }
    }
  }

}
