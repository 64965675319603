import {
  ComponentEvent,
  ComponentEventType,
  Entity,
  Injectable,
  ProcessingSystem,
  Query,
  QueryBuilder,
  Storage,
  Subscriber,
  World
} from '@heliks/tiles-engine';
import { Script } from './script';


/**
 * Executes the script handler on all entities that have a `Script` component
 * attached to them.
 *
 * @see Script
 */
@Injectable()
export class ScriptRunner extends ProcessingSystem {

  /** @internal */
  private events!: Subscriber<ComponentEvent<Script>>;

  private readonly running = new Set<Entity>();

  /** @inheritDoc */
  public build(builder: QueryBuilder): Query {
    return builder.contains(Script).build();
  }

  /** @inheritDoc */
  public boot(world: World): void {
    this.events = world.storage(Script).events.subscribe();

    super.boot(world);
  }

  private stop(world: World, component: Script, entity: Entity): void {
    component.isRunning = false;
    component.script.stop?.(world, entity);

    this.running.delete(entity);
  }

  /**
   * Starts the execution of a script `component`.
   *
   * @param world Entity world.
   * @param component Script component.
   * @param entity Owner of the component.
   * @internal
   */
  public start(world: World, component: Script, entity: Entity): boolean {
    if (component.script.start) {
      if (! component.script.start(world, entity)) {
        component.stop();

        return false;
      }
    }

    return true;
  }

  /** @internal */
  private stopRemovedScripts(world: World): void {
    for (const event of this.events.read()) {
      if (event.type === ComponentEventType.Removed && event.component.isRunning) {
        this.stop(world, event.component, event.entity);
      }
    }
  }

  /** @inheritDoc */
  public update(world: World): void {
    const store = world.storage(Script);

    this.stopRemovedScripts(world);

    for (const entity of this.query.entities) {
      const component = store.get(entity);

      if (component.isRunning) {
        // Script is running, but wasn't last frame. This means the script execution was
        // started this frame. Call the lifecycle.
        if (! this.running.has(entity)) {
          if (this.start(world, component, entity)) {
            this.running.add(entity);
          }
          else {
            continue;
          }
        }

        if (component.script.update(world, entity)) {
          this.stop(world, component, entity);
        }
      }
      else {
        // If script was running before but no longer is, it was stopped this frame.
        if (this.running.has(entity)) {
          this.stop(world, component, entity);
        }
      }
    }
  }
}
