import { EventQueue } from '@heliks/tiles-engine';


export class ItemStack {

  /**
   * @param itemId ID of the item.
   * @param slot The inventory slot where this stack is located.
   * @param amount Amount of items in the stack.
   */
  constructor(public readonly itemId: number, public slot: number, public amount: number) {}

  /** Returns `true` if there are no more items left in the stack. */
  public empty(): boolean {
    return this.amount <= 0;
  }

  /**
   * Consumes one item (amount) from the item stack. Returns `true` if there are items
   * left in the stack, and `false` if the stack has gotten empty afterwards.
   */
  public consume(): boolean {
    if (this.amount > 0) {
      this.amount--;
    }

    return this.amount > 0;
  }

}


export class Inventory {

  /** Currently selected item stack. */
  public active?: ItemStack;

  /** Contains the items that are held in this inventory. */
  public items: ItemStack[] = [];

  /** The amount of currency that the owner of this component has. */
  public balance = 0;

  /** Returns the first {@link ItemStack} for the given `itemId` found, if any. */
  public getItemStack(itemId: number): ItemStack | undefined {
    return this.items.find(stack => stack.itemId === itemId);
  }

  private getItemSlotIndex(slot: number): number {
    return this.items.findIndex(item => item.slot === slot);
  }

  public getItemSlot(slot: number): ItemStack | undefined {
    return this.items.find(item => item.slot === slot);
  }

  private getTargetItemStack(itemId: number): ItemStack {
    let stack = this.getItemStack(itemId);

    if (stack) {
      return stack;
    }

    stack = new ItemStack(itemId, this.items.length, 0);

    this.items.push(stack);

    return stack;
  }

  public add(itemId: number, amount = 1): this {
    this.getTargetItemStack(itemId).amount += amount;

    return this;
  }

  public select(slot: number): this {
    this.active = this.getItemSlot(slot);

    return this;
  }

  public unselect(): this {
    this.active = undefined;

    return this;
  }

  public remove(slot: number): boolean {
    const index = this.getItemSlotIndex(slot);

    if (~index) {
      const item = this.items[index];

      this.items.splice(index, 1);

      // If slot was the selected item, reset selection.
      if (item.slot === slot) {
        this.active = undefined;
      }

      return true;
    }

    return false;
  }

  public consume(slot: number): boolean {
    const stack = this.getItemSlot(slot);

    if (! stack) {
      return false;
    }

    // Consume item. If no items are left in the stack, remove it from the inventory.
    if (! stack.consume()) {
      this.remove(slot);
    }

    return true;
  }

}
