import { AppBuilder, Bundle } from '@heliks/tiles-engine';
import { Egg } from './egg';
import { EggHatcher } from './egg-hatcher';
import { HolderSystem } from './holder-system';
import { Pet } from './pet';
import { PetDB } from './pet-db';
import { PetFactory } from './pet-factory';


export class PetBundle implements Bundle {

  /** @inheritDoc */
  public build(builder: AppBuilder): void {
    builder
      .component(Egg)
      .component(Pet)
      .provide(PetFactory)
      .provide(PetDB)
      .system(EggHatcher)
      .system(HolderSystem);
  }

}
