import { BehaviorNode, Sequence, Succeeder } from '@heliks/tiles-ai';
import { Entity, World } from '@heliks/tiles-engine';
import { Emote } from '../../common';
import { ScriptExecutable } from '../../modules/script';
import { StatusState } from '../../status';
import { CheckNeeds, PlayAnimation, RandomChance, ReturnState, SetStatus, ShowEmote, Wait } from '../utils';
import { PetEat } from './pet-eat';
import { PetFindFood } from './pet-find-food';
import { PetBehaviorData, PetRoot } from './pet-root';
import { PetWander } from './pet-wander';


/** @internal */
function createBehaviorSequence(): Sequence<PetBehaviorData> {
  return new Sequence<PetBehaviorData>()
    // Hungry: Hold stomach
    .add(
      new Succeeder(
        new Sequence()
          .add(new CheckNeeds(needs => needs.hunger > 0.5))
          .add(new RandomChance(0.25))
          .add(new ShowEmote(Emote.Frustrated))
          .add(new PlayAnimation('HoldStomach'))
          .add(new Wait(3000))
      )
    )
    // Hungry: Look for food.
    .add(
      new Succeeder(
        new Sequence()
          .add(new CheckNeeds(needs => needs.hunger > 0.9))
          .add(new PetFindFood())
          .add(new PetEat())
      )
    )
    // Sleepy
    .add(
      new Succeeder(
        new Sequence()
          .add(new CheckNeeds(needs => needs.sleepiness > 0.70))
          .add(new RandomChance(0.25))
          .add(new ShowEmote(Emote.Sleepy))
      )
    )
    .add(
      new Succeeder(
        new Sequence()
          .add(new CheckNeeds(needs => needs.sleepiness > 0.80))
          .add(new SetStatus(StatusState.Sleeping))
          .add(ReturnState.failure())
      )
    )
    .add(new PetWander())
    .add(new Wait(1000));
}

export class PetAi implements ScriptExecutable {

  /** @internal */
  private _data!: PetBehaviorData;

  /** @internal */
  private node!: BehaviorNode<PetBehaviorData>;

  /** @inheritDoc */
  public start(world: World, entity: Entity): boolean {
    this._data = {
      entity: world.reference(entity),
      world
    };

    this.node = new PetRoot(createBehaviorSequence());

    return true;
  }

  /** @inheritDoc */
  public update(world: World, entity: Entity): boolean {
    this.node.visit(this._data);

    // Run forever.
    return false;
  }

}
