import { BehaviorNode, NodeState } from '@heliks/tiles-ai';
import { PetBehaviorData } from '../../pet/pet-root';
import { Status, StatusState } from '../../../status';


export class SetStatus implements BehaviorNode<PetBehaviorData> {

  /**
   * @param status The status that should be set.
   */
  constructor(public readonly status: StatusState) {}

  /** @inheritDoc */
  public visit(data: PetBehaviorData): NodeState {
    console.log('SET SLEEP')

    data.entity.get(Status).state = this.status;

    return NodeState.Success;
  }

}
