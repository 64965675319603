import { EntityBuilder } from '@heliks/tiles-engine';
import { Collider, RigidBody } from '@heliks/tiles-physics';
import { ItemType } from 'modules/item/item-type';
import { ItemInfo } from '../modules/item';
import { Food } from '../modules/needs';
import { CollisionFlags } from '../physics';


/** Data that is attached to {@link ConsumableItem consumable items}. */
export interface ConsumableItemData {

  /**
   * Rate at which consumption progresses while consuming this item on a scale from `0`
   * to `1`. More dense items takes longer to consume. Items with a density of `0` will
   * be consumed in single "bite", while a density of `1` can never be consumed.
   */
  density: number;

  /**
   * Fixed value of how much "hunger" will be removed from the needs of an entity when
   * they consume this item.
   */
  nutrition: number;

}

/** Implementation for consumable {@link ItemInfo items}. */
export class ConsumableItem implements ItemType<ConsumableItemData> {

  /** @inheritDoc */
  public compose(item: ItemInfo<ConsumableItemData>, builder: EntityBuilder): void {
    const consumable = new Food();

    consumable.density = item.data.density;
    consumable.nutrition = item.data.nutrition;

    const collider = Collider.circle(0.25);

    collider.group = CollisionFlags.Item;

    builder
      .use(new Food())
      .use(
        RigidBody
          .dynamic()
          .dampen(10)
          .attach(collider)
          .attach(Collider.circle(1).toSensor())
      );
  }

}
