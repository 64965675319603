import { Entity } from '@heliks/tiles-engine';
import { GameMap } from './game-map';


export class ActiveMap {

  public current?: GameMap;

  public set(map: GameMap): this {
    this.current = map;

    return this;
  }

  public get(): GameMap {
    if (! this.current) {
      throw new Error('No active map.');
    }

    return this.current;
  }


}
