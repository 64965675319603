import { Bundle, AppBuilder } from '@heliks/tiles-engine';
import { ItemRegistry } from './item-registry';
import { Inventory } from './inventory';


export class ItemBundle implements Bundle {

  /** @inheritDoc */
  public build(builder: AppBuilder): void {
    builder
      .component(Inventory)
      .provide(ItemRegistry);
  }

}
