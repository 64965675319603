import { clamp, Injectable, ProcessingSystem, Query, QueryBuilder, Ticker, World } from '@heliks/tiles-engine';
import { SpriteRender } from '@heliks/tiles-pixi';
import { Crop } from './crop';
import { CropTypeManager } from './crop-type-manager';
import { CropType, GrowStage } from './crop-type';


/**
 * System that progresses the growth progress of entities with {@link Crop} components.
 */
@Injectable()
export class GrowCrops extends ProcessingSystem {

  /**
   * @param ticker {@link Ticker}
   * @param types {@link CropTypes}
   */
  constructor(
    private readonly ticker: Ticker,
    private readonly types: CropTypeManager
  ) {
    super();
  }

  /** @inheritDoc */
  public build(builder: QueryBuilder): Query {
    return builder
      .contains(SpriteRender)
      .contains(Crop)
      .build();
  }

  /** @internal */
  private grow(crop: Crop, config: CropType, stage: GrowStage): void {
    if (config.hasNextGrowthStage(crop.stage)) {
      crop.progress += this.ticker.delta / stage.duration;
      crop.progress = clamp(crop.progress, 0, 1);

      if (crop.complete) {
        crop.stage++;
        crop.progress = 0;
      }
    }
  }

  /** @inheritDoc */
  public update(world: World): void {
    const crops = world.storage(Crop);
    const sprites = world.storage(SpriteRender);

    for (const entity of this.query.entities) {
      const crop = crops.get(entity);
      const type = this.types.get(crop.cropId);

      const stage = type.getGrowthStage(crop.stage);

      this.grow(crop, type, stage);

      sprites
        .get(entity)
        .setIndex(stage.sprite);
    }
  }

}
