export interface ItemDef<D = unknown> {

  /** Data attached to this item. */
  data?: D;

  /** Path to the spritesheet that is being used to display the item sprite. */
  spritesheet: string;

  /** Index of the sprite that is being used to display the item sprite. */
  spriteIndex: number;

  /** ID of the items {@link ItemType item type}. */
  type: number;

  /**
   * Icon used for this item in a UX context. If not set manually, the icon will be
   * created from the items sprite.
   */
  icon?: {
    spritesheet: string;
    spriteIndex: number;
  };

}
