import { Grid } from "@heliks/tiles-engine";

export class DataGrid<T> {

  public readonly data: T[] = [];

  public set(cell: number, value: T): this {
    this.data[ cell ] = value;

    return this;
  }

  public remove(cell: number): this {
    delete this.data[ cell ];

    return this;
  }

  public isFree(cell: number): boolean {
    return this.data[ cell ] === undefined;
  }

}