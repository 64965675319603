import { Injectable, ProcessingSystem, Query, QueryBuilder, World } from '@heliks/tiles-engine';
import { Food } from './food';
import { SpriteRender } from '@heliks/tiles-pixi';


@Injectable()
export class FoodSystem extends ProcessingSystem {

  /** @inheritDoc */
  public build(builder: QueryBuilder): Query {
    return builder.contains(Food).build();
  }

  /** @inheritDoc */
  public update(world: World): void {
    const store = world.storage(Food);
    const sprites = world.storage(SpriteRender);

    for (const entity of this.query.entities) {
      const food = store.get(entity);

      // If food has been eaten 100%, remove it.
      if (food.consumption >= 1) {
        world.destroy(entity);
      }

      // Scale down sprites of partially consumed foods. Minimum scale is 50%.
      if (sprites.has(entity)) {
        const sprite = sprites.get(entity);
        const factor = 0.5 + (0.5 - (food.consumption / 2));

        sprite.scale.x = factor;
        sprite.scale.y = factor;
      }
    }
  }

}
