import { AssetCollection, AssetLoader } from '@heliks/tiles-assets';
import { Entity, Stack, State, Subscriber, World } from '@heliks/tiles-engine';
import { CameraEffects, ZoomTo } from '@heliks/tiles-pixi';
import {
  AlignContent,
  composeUi,
  FlexDirection,
  Rect,
  Size,
  UiComposer,
  UiEvent,
  UiNode,
  UiText
} from '@heliks/tiles-ui';
import { PlayerInventory } from '../../common';
import { Data } from '../../common/data';
import { ItemInfo, ItemRegistry } from '../../modules/item';
import { RenderLayer } from '../../renderer';
import { ShopAssets } from './shop-assets';
import { ShopOffer } from './shop-offer';


enum ShopButtonType {
  Exit,
  Buy
}

function onBuyItemInteraction(world: World, event: UiEvent): void {
  if (event.isUp()) {
    const offer = world.storage(ShopOffer).get(event.target);
    const inventory = world.get(PlayerInventory);

    if (inventory.balance >= offer.price) {
      console.log('Successfully bought item.', inventory);
      inventory.add(offer.itemId);
      inventory.balance -= offer.price;
    }
    else {
      console.log('Not enough money.');
    }
  }
}

/** @internal */
function createItemOfferIcon(container: UiComposer, item: ItemInfo): void {
  container
    .row({
      align: AlignContent.Center,
      justify: AlignContent.Center,
      size: new Rect<Size>(
        Size.px(32),
        Size.px(32)
      )
    })
    .sprite(item.icon.spritesheet, item.icon.spriteIndex);
}

/** @internal */
function createItemOfferPrice(container: UiComposer, offer: ShopOffer): void {
  container
    .row({
      align: AlignContent.Center,
      justify: AlignContent.Center,
      size: new Rect<Size>(
        Size.percent(1),
        Size.px(16)
      )
    })
    .text(offer.price.toString(), 0xFFFFFF, 9);
}

export class Shop implements State<World> {

  /** Assets required to display the shop interface.*/
  private assets!: AssetCollection<ShopAssets>;

  /** Widget where we display the players currency balance. */
  private balance = new UiText('0').font('Monogram', 24, 0xFFFFFF).border(1, 0x0);

  /** Contains the root entity of the shop UI. */
  private root!: Entity;

  /** Contains the {@link UiNode} component of the {@link root} UI root. */
  private node!: UiNode;
  private sub!: Subscriber<UiEvent>;

  /**
   * @param offers Offers that the user can buy.
   */
  constructor(private readonly offers: ShopOffer[]) {}

  /** @internal */
  private createToolbarUi(root: UiComposer): UiComposer {
    const container = root.row({
      align: AlignContent.Center,
      justify: AlignContent.SpaceBetween,
      size: new Rect<Size>(
        Size.percent(1),
        Size.auto()
      )
    });

    // Balance
    container.widget(this.balance, {
      margin: [0, 0, 0, 5]
    });

    // Exit Button
    container
      .sprite(this.assets.data.buttons, 1)
      .use(new Data(ShopButtonType.Exit))
      .listen();

    return container;
  }

  createItemOfferUi(composer: UiComposer, registry: ItemRegistry, offer: ShopOffer): UiComposer {
    const item = registry.getItem(offer.itemId);

    const container = composer
      .column({
        margin: [0, 5, 0, 5],
        size: new Rect<Size>(
          Size.px(32),
          Size.auto()
        )
      })
      .listen()
      .use(new Data(ShopButtonType.Buy))
      .use(offer)
      .sprite(this.assets.data.itemBg, 0)
      .column();

    createItemOfferIcon(container, item);
    createItemOfferPrice(container, offer);

    return container;
  }

  /** @internal */
  private createItemOfferContainerUi(root: UiComposer): UiComposer {
    return root
      .texture(this.assets.data.bg, {
        align: AlignContent.Center,
        justify: AlignContent.Center
      })
      .row({
        margin: [20, 0, 0, 0]
      });
  }

  /** @internal */
  private createShopUi(world: World): Entity {
    const root = composeUi(world, {
      justify: AlignContent.SpaceBetween,
      direction: FlexDirection.Column,
      layer: RenderLayer.UI,
      size: new Rect<Size>(
        Size.percent(1),
        Size.percent(1)
      )
    });

    // This is where pricing, exit buttons, etc. is located.
    this.createToolbarUi(root);

    // Shopping offers.
    const container = this.createItemOfferContainerUi(root);
    const registry = world.get(ItemRegistry);

    for (const offer of this.offers) {
      this.createItemOfferUi(container, registry, offer);
    }

    return root.entity;
  }

  /** @inheritDoc */
  public onStart(world: World): void {
    this.assets = world.get(AssetLoader).collection(ShopAssets);

    this.root = this.createShopUi(world);
    this.node = world.storage(UiNode).get(this.root);

    this.sub = this.node.onInteract.subscribe();
  }

  /** @inheritDoc */
  public update(stack: Stack<State<World>>, world: World): void {
    const $data = world.storage(Data<ShopButtonType>);

    this.balance.value = world.get(PlayerInventory).balance.toString();

    for (const event of this.sub.read()) {
      const type = $data.get(event.target).data;

      switch (type) {
        case ShopButtonType.Exit:
          if (event.isUp()) {
            stack.pop();

            return;
          }
          break;
        case ShopButtonType.Buy:
          onBuyItemInteraction(world, event);
          break;
      }
    }
  }

  /** @inheritDoc */
  public onStop(world: World): void {
    // Move camera to shopkeeper.
    world.get(CameraEffects).add(new ZoomTo(1, 0.5));

    world.destroy(this.root);
  }

}