import { clamp, Injectable, Subscriber } from '@heliks/tiles-engine';
import { Camera } from '@heliks/tiles-pixi';
import { Gestures, GestureType } from '../modules/input/gestures';
import { Input, WheelDirection } from '../modules/input/input';


@Injectable()
export class ZoomHandler {

  private onBrowserZoom: Subscriber<WheelDirection>;
  private onTouchGesture: Subscriber<HammerInput>;

  private pinchStartZoom = 0;

  /** Maximum camera zoom. */
  public maxZoom = 2;

  /** Minimum camera zoom. */
  public minZoom = 0.5;


  public wheelZoomDistance = 0.05;

  constructor(
    public readonly camera: Camera,
    public readonly gestures: Gestures,
    public readonly input: Input
  ) {
    this.onBrowserZoom = input.onZoom.subscribe();
    this.onTouchGesture = gestures.subscribe();
  }

  public update(): void {
    let zoom = this.camera.zoom;

    for (const event of this.onBrowserZoom.read()) {
      zoom += event === WheelDirection.Up ? -this.wheelZoomDistance : this.wheelZoomDistance;
    }

    for (const event of this.onTouchGesture.read()) {
      switch (event.type) {
        case GestureType.PinchStart:
          this.pinchStartZoom = this.camera.zoom;
          break;
        case GestureType.PinchMove:
          zoom = this.pinchStartZoom * event.scale;
          break;
        case GestureType.PinchStop:
          break;
      }
    }

    this.camera.zoom = clamp(zoom, this.minZoom, this.maxZoom);
  }

}

