import { EntityBuilder, Injectable, World } from '@heliks/tiles-engine';
import { AssetLoader, Handle } from '@heliks/tiles-assets';
import { LayerId, SpriteRender, SpriteSheet } from '@heliks/tiles-pixi';
import { ItemDef } from './item-def';
import { ItemInfo, ItemSpriteConfig } from './item-info';
import { ItemType } from './item-type';



@Injectable()
export class ItemRegistry {

  /**
   * Contains all available {@link ItemInfo items}, mapped to their ID.
   *
   * @internal
   */
  private readonly items = new Map<number, ItemInfo>();

  /**
   * Contains all available {@link ItemType item types}, mapped to their ID.
   *
   * @internal
   */
  private readonly types = new Map<number, ItemType>();

  constructor(private readonly loader: AssetLoader) {}

  /**
   * @internal
   * @see handles
   */
  private getAssetHandle(def: ItemDef): Handle<SpriteSheet> {
    return this.loader.load(def.spritesheet);
  }

  /**
   * Registers a {@link ItemType item type} using the given `id`. Throws an error if
   * that ID is already used by a different type.
   */
  public setType(id: number, type: ItemType): this {
    if (this.types.has(id)) {
      throw new Error(`Item type ID ${id} is already in use.`);
    }

    this.types.set(id, type);

    return this;
  }

  /**
   * Returns the {@link ItemType item type} with the given `id`. Throws an error if no
   * type with that ID exists.
   */
  public getType<D = unknown>(id: number): ItemType<D> {
    const type = this.types.get(id);

    if (! type) {
      throw new Error(`Invalid item type ${id}`);
    }

    return type;
  }

  /** @internal */
  private createSpriteConfig(path: string, sprite: number): ItemSpriteConfig {
    return {
      spritesheet: this.loader.load(path),
      spriteIndex: sprite
    };
  }

  public setItem(id: number, def: ItemDef): ItemInfo {
    if (this.items.has(id)) {
      throw new Error(`An item with ID ${id} already exists.`);
    }

    const sprite = this.createSpriteConfig(def.spritesheet, def.spriteIndex);

    // Set custom icon. If item does not have a custom icon, inherit the items sprite
    // as its icon.
    const icon = def.icon ? this.createSpriteConfig(def.icon.spritesheet, def.icon.spriteIndex) : sprite;

    const item = new ItemInfo(
      id,
      def.type,
      def.data,
      sprite,
      icon
    );

    this.items.set(id, item);

    return item;
  }

  public getItem(id: number): ItemInfo {
    const item = this.items.get(id);

    if (! item) {
      throw new Error(`Invalid item ID ${id}`);
    }

    return item;
  }

  public sprite(world: World, id: number, group?: LayerId): EntityBuilder {
    const item = this.getItem(id);

    return world
      .create()
      .use(new SpriteRender(
        item.sprite.spritesheet,
        item.sprite.spriteIndex,
        group
      ));
  }

  public build(world: World, id: number, group?: LayerId): EntityBuilder {
    const item = this.getItem(id);

    const builder = world
      .create()
      .use(item)
      .use(new SpriteRender(
        item.sprite.spritesheet,
        item.sprite.spriteIndex,
        group
      ));

    this.getType(item.type).compose(item, builder);

    return builder;
  }

}
