import { Vec2, World } from '@heliks/tiles-engine';
import { ActiveMap } from '../modules/map/active-map';
import { GameMap } from '../modules/map/game-map';
import { Housing } from '../modules/map/housing/housing';
import { Tool } from '../modules/tool';


/**
 * A tool that edits the {@link ActiveMap}.
 *
 * @see Tool
 * @see GameMap
 */
export abstract class MapEditTool implements Tool {

  /** Housing system for the active {@link map}. */
  protected housing!: Housing;

  /** Active game map. */
  protected map!: GameMap;

  /** @internal */
  readonly #scratch = new Vec2();

  /** @inheritDoc */
  public abstract use(world: World, pos: Vec2): void;

  /** @inheritDoc */
  public start(world: World, pos: Vec2): boolean {
    const map = world.get(ActiveMap);

    // Check if we have an active map that supports housing.
    if (map.current && map.current.housing) {
      this.map = map.current;
      this.housing = map.current.housing;

      return true;
    }

    return false;
  }

  /** Converts a world position `pos` to a position relative to the housing grid. */
  protected getHousingGridPos(pos: Vec2): Vec2 | undefined {
    this.#scratch.x = pos.x + (this.housing.grid.width / 2);
    this.#scratch.y = pos.y + (this.housing.grid.height / 2);

    if (
      this.#scratch.x > 0 &&
      this.#scratch.y > 0 &&
      this.#scratch.x < this.housing.grid.width &&
      this.#scratch.y < this.housing.grid.height
    ) {
      return this.#scratch;
    }
  }

  /**
   * Returns the index of a {@link housing} grid cell that is located at the given
   * world position `pos`.
   */
  protected getHousingGridCellAt(pos: Vec2): number | undefined {
    const relative = this.getHousingGridPos(pos);

    if (relative) {
      return this.housing.grid.getIndexAt(relative.x, relative.y);
    }
  }

}
