import { Injectable, Vec2 } from '@heliks/tiles-engine';
import { Camera } from '@heliks/tiles-pixi';
import { Cursor } from './modules/input/cursor';


/**
 * Services that allows the user to drag the {@link Camera} with the {@link Cursor}.
 *
 * This service must be manually updated for the drag to take effect.
 */
@Injectable()
export class CameraDrag {

  private ogCursorPos = new Vec2();
  private ogCameraPos = new Vec2();

  /** @internal */
  private readonly diff = new Vec2();

  private get scale(): number {
    return 1 / this.camera.unitSize;
  }

  constructor(
    private readonly camera: Camera,
    private readonly cursor: Cursor
  ) {}

  public start(): this {
    this.ogCameraPos.copy(this.camera.world);
    this.ogCursorPos.copy(this.cursor.screen);

    return this;
  }

  public update(): void {
    // Get distance between cursor start & current position.
    this.diff
      .copy(this.cursor.screen)
      .sub(this.ogCursorPos)
      .scale(this.scale);

    // Position of camera on drag start - difference is new camera position.
    this.camera.world.copy(this.ogCameraPos).sub(this.diff);
  }

}