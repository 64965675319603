import { AppBuilder, Bundle } from '@heliks/tiles-engine';
import { TmxBundle, TmxSpawnerBundle } from '@heliks/tiles-tmx';
import { UNIT_SIZE } from '../const';
import { MapBundle } from '../modules/map/map-bundle';
import { DefaultObjectType } from './default-object-type';


export class GardenMapBundle implements Bundle {

  public build(builder: AppBuilder): void {
    builder
      .bundle(new TmxBundle())
      .bundle(new TmxSpawnerBundle(UNIT_SIZE).type(DefaultObjectType))
      .bundle(new MapBundle());
  }

}