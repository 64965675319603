import { Entity, Query, Storage, Transform, Vec2, World } from '@heliks/tiles-engine';
import { Interaction, Script } from '../modules/script';
import { Tool } from '../modules/tool';
import { DragCamera } from './drag-camera';
import { Interact } from './interact';


/**
 * Tool to trigger interactions with entities that have an {@link Interaction} component.
 *
 * @SEE Interaction
 * @see Tool
 */
export class Hand implements Tool {

  /**
   * The (sub) tool that is currently being used. This will be determined at the start
   * when the hand is first used.
   */
  private tool!: Tool;

  /** @internal */
  private query!: Query;

  /** @internal */
  private interactions!: Storage<Interaction>;

  /** @internal */
  private transforms!: Storage<Transform>;

  /** @internal */
  private _dist = new Vec2();

  /** @inheritDoc */
  public init(world: World): void {
    this.interactions = world.storage(Interaction);
    this.transforms = world.storage(Transform);

    this.query = world
      .query()
      .contains(Interaction)
      .contains(Transform)
      .contains(Script)
      .build();
  }

  /** @internal */
  private getClosestInteraction(pos: Vec2): Entity | undefined {
    let closest;
    let closestDist = Infinity;

    for (const entity of this.query.entities) {
      const interaction = this.interactions.get(entity);

      const dist = pos.distance(
        this._dist.copy(this.transforms.get(entity).world).add(interaction.offset)
      );

      if (dist <= interaction.radius && dist < closestDist) {
        closest = entity;
        closestDist = dist;
      }
    }

    return closest;
  }

  /** @inheritDoc */
  public start(world: World, pos: Vec2): boolean {
    const entity = this.getClosestInteraction(pos);

    // If user pressed on an entity, start interacting with that entity. Otherwise, start
    // dragging the camera.
    this.tool = entity !== undefined ? new Interact(entity) : new DragCamera();
    this.tool.start?.(world, pos);

    return true;
  }

  /** @inheritDoc */
  public stop(world: World, pos: Vec2): void {
    this.tool.stop?.(world, pos);
  }

  /** @inheritDoc */
  public use(world: World, pos: Vec2): void {
    this.tool.use(world, pos);
  }

}
