import { Vec2, World, XY } from '@heliks/tiles-engine';


/**
 * Implementation of a tool, or in other words, the logic that happens when the user
 * interacts with the game world. All positions are the world position where the user
 * interacted.
 */
export interface Tool {

  /** If defined, will be called when the tool is first initialized. */
  init?(world: World): void;

  /**
   * If defined, will be called when the tool is started to be used. When `false` is
   * returned by this function, the usage will be canceled.
   */
  start?(world: World, pos: Readonly<XY>): boolean;

  /** If defined, will be called when the tool is stopped to be used. */
  stop?(world: World, pos: Readonly<XY>): void;

  /**  */
  use(world: World, pos: Readonly<XY>): void;

}
