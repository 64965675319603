import { Entity } from "@heliks/tiles-engine";


/**
 * Component that when attached to an entity, indicates that the owner of this component
 * is physically being held by another entity. E.g. in this context, "Holder" means the
 * entity is being held, rather than it is the holder of another entity. Entities that
 * are being held have their rigid body disabled and can not be interacted with until
 * they are released again.
 *
 * Depending on the owner, this could be an item that is held in the hands of another
 * entity like food or other consumables. It could also be an item that is loaded as
 * cargo on a wagon. The principle is the same.
 */
export class Holder {

  /**
   * @param entity The entity that is physically holding the owner of this component.
   */
  constructor(public readonly entity: Entity) {}

}
