import { clamp, Entity, Stack, State, Subscriber, System, Vec2, World } from '@heliks/tiles-engine';
import { Camera } from '@heliks/tiles-pixi';
import { createScreenshotDownload, PlayerInventory } from '../../common';
import { ZoomHandler } from '../../common/zoom';
import { Cursor } from '../../modules/input/cursor';
import { Gestures, GestureType } from '../../modules/input/gestures';
import { Input, KeyCode, WheelDirection } from '../../modules/input/input';
import { ItemRegistry } from '../../modules/item';
import { ActiveTool, Tool } from '../../modules/tool';
import { Hand } from '../../tools';
import { InventoryToolbarUi } from '../../ui/inventory';
import { VillageTransition } from '../village';


export class Garden implements State<World> {

  /** @internal */
  private activeTool!: ActiveTool;

  /** @internal */
  private camera!: Camera;

  /** @internal */
  private cursor!: Cursor;

  /** Player inventory toolbar UI (e.g. toggle inventory, active item, etc.) */
  private inventory!: InventoryToolbarUi;

  private zoom!: ZoomHandler;

  /** @inheritDoc */
  public onStart(world: World): void {
    this.activeTool = world.get(ActiveTool);
    this.camera = world.get(Camera);
    this.cursor = world.get(Cursor);
    this.inventory = InventoryToolbarUi.create(world);
    this.zoom = world.make(ZoomHandler);
  }

  /** @inheritDoc */
  public onStop(world: World): void {
    this.action?.stop?.(world, this.cursor.world(this.camera));
  }

  private action?: Tool;

  private distance = 0.05;
  private maxZoom = 0.5;
  private minZoom = 2;

  private org = 0;

  /** @inheritDoc */
  public update(stack: Stack<State<World>>, world: World): void {
    // Todo: Testing purposes.
    const input = world.get(Input);

    if (input.isKeyDownThisFrame(KeyCode.F10)) {
      createScreenshotDownload(world);
    }

    if (input.isKeyDownThisFrame(KeyCode.R)) {
      stack.switch(new VillageTransition());

      return;
    }

    this.zoom.update();
    this.inventory.update(world);


    // this.toolbar.update(world);
    // this.activeTool.inUse = this.cursor.isDown;

    if (this.cursor.isDownNow) {
      const inventory = world.get(PlayerInventory);

      if (inventory.active) {
        const items = world.get(ItemRegistry);
        const item = items.getItem(inventory.active.itemId);
        const type = items.getType(item.type);

        // Create the action from the item type of the actively selected stack.
        if (type.getAction) {
          this.action = type.getAction(inventory.active);
          this.action.init?.(world);
          this.action.start?.(world, this.cursor.world(this.camera));
        }
      }
      else {
        const hand = new Hand();

        hand.init(world);
        hand.start(world, this.cursor.world(this.camera) as any);

        this.action = hand;
      }
    }
    else if (this.cursor.isDown && this.action) {
      this.action.use(world, this.cursor.world(this.camera))
    }
    else if (this.action) {
      this.action.stop?.(world, this.cursor.world(this.camera))
      this.action = undefined;
    }
  }

}
