import { Entity, World } from '@heliks/tiles-engine';


/**
 * Implements the executable logic of a script component.
 *
 * @see Script
 */
export interface ScriptExecutable<T = unknown> {

  /**
   * Data that can be attached to a script executable.
   *
   * When the {@link Script} component that uses this executable is serialized, this data
   * will be serialized as well. For this reason it is important that it can be used as
   * an input for `JSON.stringify()`.
   */
  data?: T;

  /**
   * If defined, called before the script is executed. The script execution can be
   * stopped by returning `false`.
   *
   * @param world World in which the script exists.
   * @param entity Entity on which this script is called.
   */
  start?(world: World, entity: Entity): boolean;

  /**
   * If defined, called when the script stops being executed.
   *
   * @param world World in which the script exists.
   * @param entity Entity on which this script is called.
   */
  stop?(world: World, entity: Entity): void;

  /**
   * Implementation of the script logic.
   *
   * When the script is executed, this is called once per frame until it returns `true`,
   * at which point the script execution will be stopped.
   *
   * @param world Entity world in which the script of this executable was called.
   * @param entity Entity on which the script is executed.
   */
  update(world: World, entity: Entity): boolean;

}

/**
 * Script component.
 *
 * While the script is running, its {@link ScriptExecutable} will be called once per frame
 * until the execution is stopped.
 *
 * To be able to serialize this component, the {@link ScriptExecutable} that is used by
 * this component must be registered on the {@link ScriptRegistry}.
 */
export class Script<S extends ScriptExecutable = ScriptExecutable> {

  /** Indicates if the script is currently running. */
  public isRunning = true;

  /**
   * @param script
   */
  constructor(public readonly script: S) {}

  /** Starts executing this script. */
  public start(): this {
    this.isRunning = true;

    return this;
  }

  /** Stops executing this script. */
  public stop(): this {
    this.isRunning = false;

    return this;
  }

}
