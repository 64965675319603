import { Entity, Subscriber, System, Vec2, World } from '@heliks/tiles-engine';
import { SpriteSheet } from '@heliks/tiles-pixi';
import { AlignContent, composeUi, Rect, Size, UiComposer, UiEvent, UiSprite, UiText } from '@heliks/tiles-ui';
import { AssetLoader, Handle } from '@heliks/tiles-assets';
import { PlayerInventory } from '../../common';
import { Data } from '../../common/data';
import { ItemInfo, ItemRegistry } from '../../modules/item';
import { ItemStack } from '../../modules/item/inventory';
import { RenderLayer } from '../../renderer';
import { InventoryUi } from './inventory-ui';


enum InventoryToolbarButton {
  /** Clears the actively selected item in the player inventory. */
  ClearSelectedItem,
  /** Toggles the player inventory view. */
  ToggleInventory
}

export class InventoryToolbarUi implements System {

  private readonly subscription: Subscriber<UiEvent>;
  private inventory?: InventoryUi;
  private selection?: UiComposer;

  /**
   * @param asset Inventory assets.
   * @param root Inventory toolbar element.
   */
  constructor(public readonly asset: Handle<SpriteSheet>, public readonly root: UiComposer) {
    // Subscribe to all node interaction events.
    this.subscription = this.getToolbarContainer().component.onInteract.subscribe();
  }

  public static create(world: World): InventoryToolbarUi {
    // Load inventory assets.
    const asset = world.get(AssetLoader).load('ux/inventory.spritesheet');

    const root = composeUi(world, {
      align: AlignContent.End,
      layer: RenderLayer.UI,
      size: new Rect<Size>(
        Size.percent(1),
        Size.percent(1),
      )
    })
    .column({
      // Todo: https://trello.com/c/E7fxvElO/125
      margin: [-5, 0, 0, 5]
    });

    // Container where the inventory is shown when it is being opened.
    root.node();

    // Container for the actual toolbar.
    const container = root.row();

    // Menu button.
    container
      .sprite(asset, 0, {
        justify: AlignContent.Center,
        align: AlignContent.Center,
      })
      .use(new Data(InventoryToolbarButton.ToggleInventory))
      .listen();

    // Clear selection. Only visible when an item is selected.
    container
      .sprite(asset, 1, {
        margin: [0, 0, 0, -2]
      })
      .use(new Data(InventoryToolbarButton.ClearSelectedItem))
      .listen()

    return new InventoryToolbarUi(asset, root);
  }

  public getInventoryContainer(): UiComposer {
    return this.root.children[0];
  }

  public getToolbarContainer(): UiComposer {
    return this.root.children[1];
  }

  /** @internal */
  private createActiveItemPreview(item: ItemInfo, active: ItemStack): void {
    const toolbar = this.getToolbarContainer();

    this.selection = toolbar.children[0].node({
      align: AlignContent.End
    });

    this.selection.sprite(item.sprite.spritesheet, item.sprite.spriteIndex);
    this.selection.text(active.amount.toString(), undefined, undefined, {
      margin: [5, 0, 0, -3]
    });

    // Show button to clear active item.
    toolbar.children[1].component.show();
  }

  /** @internal */
  private updateActiveItemPreview(world: World, active: ItemStack): void {
    const item = world.get(ItemRegistry).getItem(active.itemId);

    if (this.selection) {
      // Update item sprite.
      this.selection
        .getChildAt<UiSprite>(0)
        .component
        .getWidget()!
        .set(item.sprite.spritesheet, item.sprite.spriteIndex);

      // Update stack amount display.
      this.selection.getChildAt<UiText>(1).component.getWidget()!.value = active.amount.toString();
    }
    else {
      this.createActiveItemPreview(item, active);
    }
  }

  /** @internal */
  private removeActiveItemPreview(): void {
    this.selection?.destroy();
    this.selection = undefined;

    // Hide button to clear active item.
    this.getToolbarContainer().children[1].component.hide();
  }

  /** @internal */
  private updateActiveItem(world: World): void {
    const active = world.get(PlayerInventory).active;

    if (active) {
      this.updateActiveItemPreview(world, active);
    }
    else {
      this.removeActiveItemPreview();
    }
  }

  /** Closes the player inventory UI if it is currently open. */
  private close(): void {
    if (this.inventory) {
      this.inventory.container.destroy();
      this.inventory = undefined;
    }
  }

  /** Toggles the player inventory UI. */
  private toggle(world: World): void {
    if (this.inventory) {
      this.close();
    }
    else {
      this.inventory = InventoryUi.create(
        world,
        this.getInventoryContainer(),
        this.asset
      );
    }
  }

  /** @inheritDoc */
  public update(world: World): void {
    for (const event of this.subscription.read()) {
      if (event.isUp()) {
        const type = world.storage(Data<InventoryToolbarButton>).get(event.target).data;

        switch (type) {
          case InventoryToolbarButton.ClearSelectedItem:
            world.get(PlayerInventory).unselect();
            break;
          case InventoryToolbarButton.ToggleInventory:
            this.toggle(world);
            break;
        }
      }
    }

    if (this.inventory?.update(world)) {
      this.close();
    }

    this.updateActiveItem(world);
  }

}