import {
  Injectable,
  OnInit,
  ProcessingSystem,
  Query,
  QueryBuilder,
  Transform,
  World
} from '@heliks/tiles-engine';
import { Interaction } from './interaction';
import { Graphics } from 'pixi.js';
import { Stage } from '@heliks/tiles-pixi';
import { UNIT_SIZE } from '../../const';
import { RenderLayer } from '../../renderer';


/**
 * Renderer plugin that draws circle around entities that can be interacted with.
 *
 * @see Interaction
 */
@Injectable()
export class DrawInteractionRadius extends ProcessingSystem implements OnInit {

  /** @internal */
  private readonly view = new Graphics();

  constructor(private readonly stage: Stage) {
    super();
  }

  /** @inheritDoc */
  public build(builder: QueryBuilder): Query {
    return builder.contains(Interaction).build();
  }

  /** @inheritDoc */
  public onInit(world: World): void {
    this.boot(world);

    this.stage.add(this.view, RenderLayer.Above);
    this.view.zIndex = 9999;
  }

  /** @inheritDoc */
  public update(world: World): void {
    this.view.clear().lineStyle(1, 0x00F2FF);

    const interactions = world.storage(Interaction);
    const transforms = world.storage(Transform);

    for (const entity of this.query.entities) {
      const interaction = interactions.get(entity);
      const transform = transforms.get(entity);

      const x = (transform.world.x + interaction.offset.x) * UNIT_SIZE;
      const y = (transform.world.y + interaction.offset.y) * UNIT_SIZE;

      this.view.drawCircle(x, y, interaction.radius * UNIT_SIZE);
    }
  }

}
