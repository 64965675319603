import { EntityRef, Grid } from '@heliks/tiles-engine';
import { Tilemap } from '@heliks/tiles-tilemap';
import { TmxMapAsset } from '@heliks/tiles-tmx';
import { Housing } from './housing/housing';


/**
 * Resource that holds references to some entities that hold things such as ground
 * tilemaps.
 */
export interface GameMapLayout {

  /**
   * Tilemap on which the first layer of the ground is rendered. The data of this is
   * generated and can not be edited by the user.
   */
  ground1: EntityRef<Tilemap | unknown>;

  /**
   * Tilemap on which the second layer of the ground is rendered. The data of this can
   * be edited by the user through various means. By default, it contains no data.
   */
  ground2: EntityRef<Tilemap | unknown>;

}

export class GameMap {

  /**
   * Contains the maps housing information. Not all maps necessarily support player
   * housing, in which case this will be empty.
   */
  public housing?: Housing;

  /**
   * @param asset The TMX map asset from which this game map was created.
   * @param grid Map boundaries.
   */
  constructor(
    public readonly asset: TmxMapAsset,
    public readonly grid: Grid
  ) {}

}

export interface GameMapWithHousing extends GameMap {

  /** @inheritDoc */
  housing: Housing;

}


