import { SpriteAnimation } from '@heliks/tiles-pixi';
import { Movement } from '../../modules/movement';
import { Compass, Entity, EntityRef, Transform, Vec2, vecToCompass, World } from '@heliks/tiles-engine';
import { ItemInfo } from '../../modules/item';
import { Food } from '../../modules/needs';


export function playWalkAnimation(animation: SpriteAnimation, movement: Movement): void {
  const direction = vecToCompass(movement.direction);

  switch (direction) {
    case Compass.S:
      animation.play('WalkSouth');
      break;
    case Compass.N:
      animation.play('WalkNorth');
      break;
    case Compass.E:
    case Compass.NE:
    case Compass.SE:
      animation.play('WalkEast').flip(false);
      break;
    case Compass.W:
    case Compass.NW:
    case Compass.SW:
      animation.play('WalkEast').flip(true);
  }

  animation.frameDuration = 150;
}

export function playSleepAnimation(entity: EntityRef): void {
  const animation = entity.get(SpriteAnimation).play('Sleep');

  switch (vecToCompass(entity.get(Movement).direction)) {
    case Compass.N:
    case Compass.E:
    case Compass.NE:
    case Compass.SE:
      animation.flip(false);
      break;
    case Compass.S:
    case Compass.W:
    case Compass.NW:
    case Compass.SW:
      animation.flip(true);
      break;
  }
}

/**
 * Returns the entity with a `Food` component that is the closest to `position`, or
 * `undefined` if there are no valid food sources available.
 */
export function getClosestFood(world: World, position: Transform): Entity | undefined {
  const query = world.query()
    .contains(Transform)
    .contains(ItemInfo)
    .contains(Food)
    .build();

  const foods = world.storage(Food);
  const transforms = world.storage(Transform);

  let dist = Infinity;
  let match;

  for (const entity of query.entities) {
    const food = foods.get(entity);

    // Ignore foods that are already eaten by someone else.
    if (food.consumer !== undefined) {
      continue;
    }

    const distance = Vec2.distance(transforms.get(entity).world, position.world);

    if (distance < dist) {
      dist = distance;
      match = entity;
    }
  }

  return match;
}
