import { Injectable, System, World, XY } from '@heliks/tiles-engine';
import { Camera } from '@heliks/tiles-pixi';
import { Cursor } from '../input/cursor';
import { Tool } from './tool';


/** Manages the active tool. */
@Injectable()
export class ActiveTool implements System {

  /** Contains the tool that is actively being used. */
  public current?: Tool;

  public inUse = false;

  /** Indicates if the active tool is currently being used. */
  private _inUse = false;

  /**
   * @param camera Active camera.
   * @param cursor User mouse cursor.
   */
  constructor(private readonly camera: Camera, private readonly cursor: Cursor) {}

  /** Sets `tool` as the new active tool. */
  public set(tool: Tool): this {
    this.current = tool;
    this._inUse = false;

    return this;
  }

  /** Returns `true` if `tool` is currently the active tool. */
  public is(tool: Tool): boolean {
    return this.current === tool;
  }

  /**
   * Removes the {@link current} selected tool. Returns `false` if there was no tool
   * selected.
   */
  public clear(): boolean {
    if (this.current) {
      this.current = undefined;
      this._inUse = false;

      return true;
    }

    return false;
  }

  private _use(world: World, position: Readonly<XY>): boolean {
    if (! this.current) {
      return false;
    }

    // Try starting the tool. If it fails, do not use the tool.
    if (! this._inUse && this.current.start && !this.current.start(world, position)) {
      return false;
    }

    this._inUse = true;
    this.current.use(world, position);

    return true;
  }

  public _stop(world: World, position: Readonly<XY>): void {
    if (this._inUse && this.current) {
      this.current.stop?.(world, position);
      this._inUse = false;
    }
  }

  /** @inheritDoc */
  public update(world: World): void {
    // Do nothing without an active tool.
    if (! this.current) {
      this._inUse = false;

      return;
    }

    const position = this.cursor.world(this.camera);

    if (this.inUse) {
      // Try starting the tool. If it fails, do not use the tool.
      if (! this._inUse && this.current.start && !this.current.start(world, position)) {
        return;
      }

      this._inUse = true;
      this.current.use(world, position);
    }
    else {
      this._stop(world, position);
    }
  }

}
