import { easeOutBounce, Entity, getRandomFloat, lerp, Ticker, Transform, Vec2, World } from '@heliks/tiles-engine';
import { SpriteRender } from '@heliks/tiles-pixi';
import { easeOutSine } from '../easings';
import { ItemRegistry } from '../item';
import { Item } from '../item/item';
import { DragEntity } from '../../scripts/utils/drag-entity';
import { ActiveMap } from '../map/active-map';
import { HousingEntity } from '../map/housing/housing-entity';
import { Interaction, Script, ScriptExecutable } from '../script';
import { Crop } from './crop';
import { CropType } from './crop-type';
import { CropTypeManager } from './crop-type-manager';


/** @internal */
function getCropType(world: World, crop: Entity): CropType {
  return world
    .get(CropTypeManager)
    .get(
      world
        .storage(Crop)
        .get(crop)
        .cropId
    );
}

/**
 * Data for {@link Harvest} script.
 */
export interface HarvestData {
  /** Amount of units that the crop falls to the ground after being picked. */
  height: number;
  /** Milliseconds that it takes the crop to fall down to the ground. */
  duration: number;
}

/** @internal */
function isGrowing(world: World, entity: Entity): boolean {
  const crop = world.storage(Crop).get(entity);
  const type = world.get(CropTypeManager).get(crop.cropId);

  return type.hasNextGrowthStage(crop.stage);
}

/** @internal */
function removeEntityFromHousing(world: World, entity: Entity): void {
  world.get(ActiveMap).get().housing?.hierarchy.entity.remove(
    world.storage(HousingEntity).get(entity).cell
  );
}

/**
 * Script that harvests a crop.
 *
 * When harvesting the crop, the crop will first fall a set amount of units from its
 * original entity (to simulate an apple falling from a tree, for example). Only then
 * the crop is ready to be "used".
 */
export class Harvest implements ScriptExecutable<HarvestData> {

  /**
   * Contains the entity of the fruit item that is spawned if the crop is successfully
   * harvested.
   *
   * @internal
   */
  private fruit?: Entity;

  /**
   * Start position from where the harvested crop is beginning to "fall".
   *
   * @internal
   */
  private readonly startPos = new Vec2();

  /**
   * Target position to where the harvested crop will fall.
   *
   * @internal
   */
  private readonly targetPos = new Vec2();

  /** @internal */
  private timePassed = 0;

  /**
   * @param data {@link HarvestData}
   */
  constructor(public readonly data: HarvestData) {}

  /** @internal */
  private plotItemFall(transform: Transform): void {
    this.startPos.copy(transform.world);
    this.startPos.y -= 1;

    this.targetPos
      .copy(transform.world)
      .add({
        x: getRandomFloat(0.5, -0.5),
        y: this.data.height
      });
  }

  /** @internal */
  private spawnFruit(world: World, plant: Entity): Entity {
    const trans = world
      .storage(Transform)
      .get(plant)
      .clone();

    this.plotItemFall(trans);

    return world
      .get(ItemRegistry)
      .build(
        world,
        getCropType(world, plant).itemId,
        world.storage(SpriteRender).get(plant).layer
      )
      .use(new Interaction(0.5, true))
      .use(new Item())
      .use(new Script(new DragEntity()).stop())
      .use(trans)
      .build()
  }

  /** @inheritDoc */
  public start(world: World, entity: Entity): boolean {
    // Crop needs to be complete before it can be harvested.
    if (isGrowing(world, entity)) {
      return false;
    }

    this.timePassed = 0;
    this.fruit = this.spawnFruit(world, entity);

    // Hide the plant entity for the duration of the harvest animation.
    world.storage(SpriteRender).get(entity).hide();

    return true;
  }

  /** @inheritDoc */
  public update(world: World, entity: Entity): boolean {
    if (! this.fruit) {
      return true;
    }

    this.timePassed += world.get(Ticker).delta;

    const progress = this.timePassed / this.data.duration;
    const transform = world.storage(Transform).get(this.fruit);

    transform.world.x = lerp(this.startPos.x, this.targetPos.x, easeOutSine(progress));
    transform.world.y = lerp(this.startPos.y, this.targetPos.y, easeOutBounce(progress));

    // Continue script as long as food is still falling.
    if (progress < 1) {
      return false;
    }

    removeEntityFromHousing(world, entity);

    world.destroy(entity);

    return true;
  }

}

