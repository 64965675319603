import { Inventory } from '../modules/item/inventory';


/**
 * The inventory of the player.
 *
 * @see Inventory
 */
export class PlayerInventory extends Inventory {

  constructor() {
    super();

    this.balance = 5000;
  }

}