import { clamp } from '@heliks/tiles-engine';
import { Food } from './food';


/**
 * Component that gives the entity basic needs, such as hunger, happiness, etc.
 *
 * Some needs change over time (a.E. hunger) while others (a.E. happiness) are entirely
 * dependent on external factors.
 */
export class Needs {

  /**
   * Indicates happiness on a scale from `0` to `1`. A value of `0` is very unhappy
   * and `1` is extremely happy.
   */
  public happiness = 0.5;

  /**
   * Indicates hunger on a scale from `0` to `1`. Higher means more hungry. Passively
   * increases over time based on {@link hungerPerSecond}.
   */
  public hunger = 0.5;

  /**
   * Value by which {@link hunger} will be increased over the duration of a second. This
   * means that entities with a higher hunger per second want to eat more often.
   */
  public hungerPerSecond = 0.01;

  /**
   * Scale of how sleepy the entity is from `0` to `1`.
   *
   * This value will be constantly increased while the entity is awake. If the entity is
   * asleep, it will be decreased instead.
   *
   * @see sleepinessGainAwake
   * @see sleepinessLossAsleep
   */
  public sleepiness = 0.5;

  /**
   * Value by which {@link sleepiness} will be *increased* over the duration of a second
   * while the entity is *awake*. Therefore, entities with a high sleepiness gain want
   * to sleep more often.
   */
  public sleepinessGainAwake = 0.01;

  /**
   * Value by which {@link sleepiness} will be *decreased* over the duration of a second
   * while the entity is *asleep*. Therefore, entities with a low sleepiness loss want
   * to sleep longer.
   */
  public sleepinessLossAsleep = 0.01;

  /**
   * Scale of how tired the entity is from `0` to `1`. While the entity is asleep and its
   * {@link sleepiness} is above `0`, this will be slowly increased. If it is `0`, it is
   * rapidly recovered instead.
   */
  public tiredness = 0;

  /** Sets the {@link hunger}. Value will be clamped between `0` and `1`. */
  public setHunger(value: number): this {
    this.hunger = clamp(value, 0, 1);

    return this;
  }

  /** Sets the {@link sleepiness}. Value will be clamped between `0` and `1`. */
  public setSleepiness(value: number): this {
    this.sleepiness = clamp(value, 0, 1);

    return this;
  }

  /** Sets the {@link tiredness}. Value will be clamped between `0` and `1`. */
  public setTiredness(value: number): this {
    this.tiredness = clamp(value, 0, 1);

    return this;
  }

  /**
   * Takes a "bite" from the given `food` and absorbs its nutritional value.
   *
   * @see Food.nutrition
   */
  public eat(food: Food): this {
    food.bite();

    return this.setHunger(this.hunger - food.nutrition);
  }

}
