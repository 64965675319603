import { Bundle, AppBuilder, OnInit, World } from '@heliks/tiles-engine';
import { FoodSystem } from './food-system';
import { NeedsSystem } from './needs-system';
import { Food } from './food';
import { Needs } from './needs';
import { GrowCrops } from './grow-crops';
import { Crop } from './crop';
import { CropTypeManager } from './crop-type-manager';
import { CropFactory } from './crop-factory';


export class NeedsBundle implements Bundle, OnInit {

  /** @inheritDoc */
  public build(builder: AppBuilder): void {
    builder
      .component(Crop)
      .component(Food)
      .component(Needs)
      .provide(CropTypeManager)
      .provide(CropFactory)
      .system(FoodSystem)
      .system(GrowCrops)
      .system(NeedsSystem);
  }

  /** @inheritDoc */
  public onInit(world: World): void {
    world.get(CropTypeManager).init();
  }

}
