import { Entity, OnInit, Parent, Storage, Query, QueryBuilder, ReactiveSystem, World } from '@heliks/tiles-engine';
import { RigidBody } from '@heliks/tiles-physics';
import { Holder } from './holder';


export class HolderSystem extends ReactiveSystem implements OnInit {

  /** @internal */
  private bodies!: Storage<RigidBody>;

  /** @inheritDoc */
  public build(builder: QueryBuilder): Query {
    return builder.contains(Holder).build();
  }

  /** @inheritDoc */
  public onInit(world: World): void {
    this.bodies = world.storage(RigidBody);
  }

  /** @internal */
  private setBodyDisabled(entity: Entity, disabled: boolean): void {
    if (this.bodies.has(entity)) {
      this.bodies.get(entity).disabled = disabled;
    }
  }

  /** @inheritDoc */
  public onEntityAdded(world: World, entity: Entity): void {
    this.setBodyDisabled(entity, true);

    // Holder entity is new parent.
    world.add(entity, new Parent(
      world
        .storage(Holder)
        .get(entity)
        .entity
    ));
  }

  /** @inheritDoc */
  public onEntityRemoved(world: World, entity: Entity): void {
    this.setBodyDisabled(entity, false);

    world
      .storage(Parent)
      .remove(entity);
  }

}
