import { Ignore } from "@heliks/tiles-engine";
import { UUID } from "@heliks/tiles-engine";


/**
 * Component that maps an entity with a {@link HousingObject} component to an entity
 * with a {@link HousingGrid} component.
 */
@Ignore()
export class HousingObjectParent {

  /**
   * @param parentId ID of a {@link HousingGrid} component.
   */
  constructor(public readonly parentId: UUID) {}

}
