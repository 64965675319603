import { Harvest } from '../modules/needs';
import { CrackEgg } from '../modules/pet/crack-egg';
import { ScriptBundle } from '../modules/script';
import { GardenTeleport, PortalAltar, PortalDoor } from './map';
import { PetAi } from './pet';
import { TestScript } from './utils/test-script';


/** Returns the {@link ScriptBundle} used by the game runtime. */
export function createScriptBundle(): ScriptBundle {
  return new ScriptBundle()
    .script('Action_CrackEgg', CrackEgg)
    .script('Action_Harvest', Harvest)
    .script('AI_Pet', PetAi)
    .script('garden_teleport', GardenTeleport)
    .script('portal_altar', PortalAltar)
    .script('portal_door', PortalDoor)
    .script('Test', TestScript)
}
