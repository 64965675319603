import { AppBuilder, Bundle } from '@heliks/tiles-engine';
import { Cursor } from './cursor';
import { CursorSystem } from './cursor-system';
import { Gestures } from './gestures';
import { Input } from './input';


/**
 * Provides tools to manage user input.
 *
 * This should be as high as possible in the execution order so that subsequent systems
 * are notified of input changes on the same frame as they occur.
 */
export class InputBundle implements Bundle {

  /** @inheritDoc */
  public build(builder: AppBuilder): void {
    builder
      .provide(Cursor)
      .provide(Gestures)
      .system(Input)
      .system(CursorSystem)
      .run(world => world.get(Gestures).setup());
  }

}
