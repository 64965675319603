import { World } from "@heliks/tiles-engine";
import { TmxMapObject } from "@heliks/tiles-tmx";

export function getObjectFromId(world: World, id: number) {
  const store = world.storage(TmxMapObject);

  return world
    .query()
    .contains(TmxMapObject)
    .build()
    .entities
    .find(entity => store.get(entity).id === id);
}