import { Uuid, UUID } from '@heliks/tiles-engine';


export enum PetAnimations {
  IdleNorth = 'IdleNorth',
  IdleEast = 'IdleEast',
  IdleSouth = 'IdleSouth',
  IdleWest = 'IdleWest'
}

export class Pet {

  /** Movement speed. */
  public speed = 5;

  /**
   * @param id Identifier that is unique across all existing pets.
   */
  constructor(public readonly id: UUID = Uuid.create()) {}

}
