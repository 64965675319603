import { AssetLoader, Handle } from '@heliks/tiles-assets';
import {
  AppBuilder,
  Bundle,
  Entity,
  EntityBuilder,
  Hierarchy,
  Injectable,
  ProcessingSystem,
  Query,
  QueryBuilder,
  World
} from '@heliks/tiles-engine';
import { SpriteSheet } from '@heliks/tiles-pixi';
import { AlignContent, Size, Style, UiAnimatedSprite, UiNode, UiSprite, Rect } from '@heliks/tiles-ui';


export type PreviewNode = UiNode<UiAnimatedSprite | UiSprite>;

export interface CarouselAnimatedPreview {
  animation: string;
}

export interface CarouselStaticPreview {
  spriteIndex: number;
}

export type CarouselPreview = CarouselAnimatedPreview | CarouselStaticPreview;

export function isAnimated<D>(item: CarouselItem<D>): item is CarouselItem<D, CarouselAnimatedPreview> {
  return Boolean((item.preview as CarouselAnimatedPreview).animation);
}

export interface CarouselItem<D, P extends CarouselPreview = CarouselPreview> {

  /** Attached data. */
  data: D;

  preview: P;

  /** Spritesheet that is used to render the preview of the carousel item. */
  spritesheet: Handle<SpriteSheet>;

}

export interface CarousalLayout {
  readonly buttonPrev: Entity;
  readonly buttonNext: Entity;
  readonly preview: Entity;
  readonly text: Entity;
}

enum ButtonId {
  Prev,
  Next
}

function createButton(builder: EntityBuilder, icons: Handle<SpriteSheet>, spriteIdx: number, id: ButtonId) {
  /*
  builder
    .use(
      new UiNode().toInteractive()
    )
    .child(child => child.use(
      UiNode.use(
        new UiSprite(
          icons,
          spriteIdx
        )
      )
    ));

   */
}

/*
function createPreviewWidget(options: CarouselOptions): UiAnimatedSprite | UiSprite {
  const item = options.items[0];

  return isAnimated(item)
    ? new UiAnimatedSprite(item.spritesheet, item.preview.animation)
    : new UiSprite(item.spritesheet, item.preview.spriteIndex);
}
 */

export class Carousel<D = unknown> {

  public active = 0;
  public readonly items: CarouselItem<D>[] = [];

  public add(item: CarouselItem<D>): this {
    this.items.push(item);

    return this;
  }

  public static spawn(world: World, options: CarouselOptions): Entity {
    const icons = world.get(AssetLoader).load('ux/icons-gui-large.spritesheet');

    const carousel = new Carousel();

    for (const item of options.items) {
      carousel.add(item);
    }

    return world
      .create()
      .use(carousel)
      /*
      .use(
        UiNode.screen(undefined, {
          align: AlignContent.Center,
          justify: AlignContent.Center,
          size: new Rect<Size>(
            Size.auto(),
            Size.percent(1)
          )
        })
      )
       */
      .child(child => createButton(child, icons, 0, ButtonId.Prev))
      .child(child => child.use(new UiNode()))
      .child(child => createButton(child, icons, 1, ButtonId.Next))
      .build();
  }

}

@Injectable()
export class CarouselSystem extends ProcessingSystem {

  constructor(private readonly hierarchy: Hierarchy) {
    super();
  }

  /** @inheritDoc */
  public build(query: QueryBuilder): Query {
    return query.contains(Carousel).contains(UiNode).build();
  }

  private getPreviewEntity(root: Entity): Entity {
    return this.hierarchy.children.get(root)![1];
  }

  private getPreviewNode(world: World, root: Entity): PreviewNode {
    return world.storage(UiNode).get(this.getPreviewEntity(root)) as PreviewNode;
  }

  /** @inheritDoc */
  public update(world: World): void {
    const components = world.storage(Carousel);

    for (const entity of this.query.entities) {
      const carousel = components.get(entity);

      const item = carousel.items[ carousel.active ];


      // carousel.preview.spritesheet = item.spritesheet;
      // carousel.preview.spriteIndex = item.spriteIndex;

      // const preview = this.getPreviewNode(world, root);

      const preview = this.getPreviewNode(world, entity);

      if (isAnimated(item)) {
        this.updateAnimatedPreview(item, preview);
      }
      else {

      }



      // const previewNode = world.storage(UiNode).get(preview);



    }
  }

  private updateAnimatedPreview(item: CarouselItem<unknown, CarouselAnimatedPreview>, preview: PreviewNode) {
    let widget = preview.getWidget();

    if (widget instanceof UiAnimatedSprite) {
      widget.animation = item.preview.animation;
    }
    else {
      preview.setWidget(new UiAnimatedSprite(
        item.spritesheet,
        item.preview.animation
      ));
    }
  }

}

export class CarouselBundle implements Bundle {

  /** @inheritDoc */
  public build(app: AppBuilder): void {
    app
      .component(Carousel)
      .system(CarouselSystem);
  }

}

export interface CarouselOptions<D = unknown> {
  /** The items that should be displayed by the carousel. */
  items: CarouselItem<D>[];
}

