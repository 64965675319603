import { Needs } from './needs';


/**
 * Amount by which {@link Needs.tiredness tiredness} will be increased over the period
 * of a second while an entity is {@link sleep sleeping}.
 */
export const NEEDS_TIREDNESS_INCREASE_PER_SECOND = 1 / 60;

/**
 * Amount by which {@link Needs.tiredness tiredness} will be decreased over the period
 * of a second while an entity is {@link sleep sleeping}.
 */
export const NEEDS_TIREDNESS_REDUCE_PER_SECOND = 1 / 20;

/**
 * Updates {@link Needs needs} as if the owner of that component were asleep. This should
 * be called once per frame while that entity is asleep.
 *
 * While sleeping, the {@link Needs.sleepiness sleepiness} need is slowly reduced. In the
 * same time, the {@link Needs.tiredness tiredness} is simultaneously increased. If the
 * sleepiness reaches a value of `0`, the tiredness is rapidly reduced instead.
 *
 * Returns `true` when both sleepiness and tiredness reach `0`, indicating that the
 * owner of this component is ready to wake up.
 *
 * The `delta` time is measured in seconds.
 */
export function sleep(needs: Needs, delta: number): boolean {
  needs.setSleepiness(needs.sleepiness - (needs.sleepinessLossAsleep * delta));

  const tiredness = needs.sleepiness > 0
    ? NEEDS_TIREDNESS_INCREASE_PER_SECOND
    : -NEEDS_TIREDNESS_REDUCE_PER_SECOND;

  needs.setTiredness(needs.tiredness + (tiredness * delta));

  return needs.sleepiness === 0 && needs.tiredness === 0;
}

/**
 * Updates {@link Needs needs} as if the owner of that component were awake. This should
 * be called once per frame while that entity is awake.
 *
 * The `delta` time is measured in seconds.
 */
export function awake(needs: Needs, delta: number): void {
  needs.setSleepiness(needs.sleepiness + (needs.sleepinessGainAwake * delta));
}
