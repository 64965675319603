import { Vec2 } from "@heliks/tiles-engine";

export enum InteractionState {
  /** Entity is not being interacted with. */
  None,
  /** Entity is started being interacted with. */
  Start,
  /** Entity is actively interacted with. */
  Active
}

/**
 * Component that tracks user interactions.
 *
 * On interaction, the script of an adjacent {@link Script} component until it is
 * stopped. The script execution does not stop on when the interaction stops.
 */
export class Interaction {

  /**
   * Offset from the entities {@link Transform transform} position where interaction
   * with that entity should be allowed.
   */
  public readonly offset = new Vec2();

  /** Current state of the interaction. */
  public state = InteractionState.None;

  /**
   * @param radius Width of the radius around the owners world transform in which the
   *  interaction will be triggered.
   * @param trigger If set to `true` and the entity is interacted with, the entity will
   *  look for an adjacent {@link Script} component and will execute it.
   */
  constructor(public radius = 1, public trigger = false) {}

  public isActiveNow(): boolean {
    return this.state === InteractionState.Start;
  }

}
